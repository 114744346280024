import React from 'react';
import { Col, Row, FormGroup, Label } from 'reactstrap';
import { CodeHolder } from 'style';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import { Input, Card } from 'components';
import { State } from 'interfaces/state';

interface Props {
    state?: State;
}

export default function HtmlTag({ state }: Props) {
    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <FormGroup row>
                            <Label style={{ textAlign: 'right' }} sm={2}>
                                Target Selector
                            </Label>
                            <Col sm={8}>
                                <Input state={state} name='content.target' />
                            </Col>
                        </FormGroup>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CodeHolder>
                        <div className='header'>Script</div>
                        <Editor
                            value={state?.current.content.script || ''}
                            name='content.script'
                            tabSize={4}
                            onValueChange={code => state?.updateAtPath('content.script', code)}
                            highlight={code => Prism.highlight(code, Prism.languages.html, 'html')}
                            padding={10}
                            style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 14,
                            }}
                        />
                    </CodeHolder>
                </Col>
            </Row>
        </>
    );
}
