import { Bidder } from 'interfaces/bidder';
import { gql } from '@apollo/client';

export const GET_BIDDER_BY_ID = gql`
    query getBidders($id: ID) {
        network @client
        getBidder(id: $id) {
            id
            name
            state
            active
            params
            alias
            bidderCode
        }
        getPrebidBidders {
            id
            name
            bidderCode
        }
    }
`;

export const GET_BIDDER_LIST = gql`
    query getBidderList($state: State) {
        stateFilter @client @export(as: "state")
        network @client
        getBidders(filter: { state: $state }) {
            id
            name
            state
            params
            alias
            bidderCode
        }
    }
`;

export const UPDATE_BIDDER = gql`
    mutation editBidders($bidderData: [BidderInput]) {
        editBidders(bidderData: $bidderData) {
            id
            name
            state
            params
            alias
            bidderCode
        }
    }
`;

export const newBidder = (name: string): Bidder => {
    const bidder = {
        name: name,
        state: 'active',
        active: true,
        params: {},
        alias: '',
        bidderCode: '',
    };
    return bidder;
};
