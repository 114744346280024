import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { Button, ToggleButton, SyntaxHighlight, Block, Card } from 'components';

import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';

const Edit = props => {
    return (
        <>
            <Container>
                <Block title='Deploy'>
                    <Row>
                        <Col>
                            <Card style={{ overflow: 'hidden' }} title='Deploy to'>
                                <ToggleButton enabled={props.env.dev} onClick={() => props.toggleEnv('dev')}>
                                    Development
                                </ToggleButton>
                                <ToggleButton
                                    enabled={props.env.prod}
                                    onClick={() => props.toggleEnv('prod')}
                                >
                                    Production
                                </ToggleButton>
                            </Card>
                        </Col>
                    </Row>

                    {props.env.dev && (
                        <SyntaxHighlight language='html' title='Development Tag' code={props.code.codeDev} />
                    )}
                    {props.env.prod && (
                        <SyntaxHighlight language='html' title='Production Tag' code={props.code.codeProd} />
                    )}

                    <Button
                        disabled={!props.env.dev && !props.env.prod}
                        loader={props.loading.publish}
                        onClick={() => {
                            const envs = Object.keys(props.env).filter(env => props.env[env] === true);
                            props.publish(props.id, envs);
                        }}
                    >
                        Publish
                    </Button>
                </Block>
            </Container>
        </>
    );
};

export default Edit;
