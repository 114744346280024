import React from 'react';
import { Menu, SideMenu, SideBarHolder, Version, SideMenuHolder } from './style';
import MainMenu from '../MainMenu';
import { useConfig } from 'config';
import packageJson from '../../../../package.json';
import { NavLink } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { folderOutline, layersOutline, cog } from 'ionicons/icons';

const Sidebar = ({ data, noSidebar }) => {
    const config = useConfig();
    const width = noSidebar ? '90px' : '340px';
    const userNetworkRole = data.user.networks[data.network].networkRole;
    return (
        <SideBarHolder style={{ width: width }}>
            <SideMenuHolder>
                <SideMenu>
                    {userNetworkRole.includes('admin') && (
                        <NavLink to={'/network'} activeClassName='active'>
                            <IonIcon icon={layersOutline} />
                            <span>Network</span>
                        </NavLink>
                    )}
                    <NavLink to={'/containers'} activeClassName='active'>
                        <IonIcon icon={folderOutline} />
                        <span>Containers</span>
                    </NavLink>
                    {userNetworkRole.includes('admin') && (
                        <NavLink to={'/admin'} activeClassName='active'>
                            <IonIcon icon={cog} />
                            <span>Admin</span>
                        </NavLink>
                    )}
                </SideMenu>
            </SideMenuHolder>
            {!noSidebar && (
                <Menu>
                    <img src={config.logo.img} alt='Logo' />
                    <MainMenu userPermission={data.user.networks[data.network].networkRole} />
                    <Version>Version: {packageJson.version}</Version>
                </Menu>
            )}
        </SideBarHolder>
    );
};

export default Sidebar;
