import React from 'react';
import { useParams } from 'react-router';
import { Block } from 'components';
import { useQuery, useMutation } from '@apollo/client';
import { ContainerType } from 'interfaces/containers';
import { Container, Row, Col } from 'reactstrap';
import { Button, Form, Input, Card, Loader, Highlight } from 'components';
import moment from 'moment';
import { notify } from 'react-notify-toast';
import { GET_CONTAINER_BY_ID, UPDATE_CONTAINER } from 'requests/containers';
import { clean, useStateHandler } from 'utils';

interface Props {
    id: string;
}
interface Mutate {
    containerData: ContainerType;
}
interface Request {
    getContainer: ContainerType;
}

const Dashboard = () => {
    const { containerId } = useParams<{ containerId: string }>();
    const query = useQuery<Request, Props>(GET_CONTAINER_BY_ID, {
        variables: { id: containerId },
    });
    const state = useStateHandler<ContainerType>(query.data?.getContainer);
    const [save, mutation] = useMutation<Request, Mutate>(UPDATE_CONTAINER, {
        onCompleted: () => {
            notify.show('Item saved', 'success', 1000);
        },
    });

    if (query.loading || !state.current.data) return <Loader />;
    if (query.error) return <p>Error :(</p>;

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <Block title={state.current.name}>
                        <Row>
                            <Form state={state}>
                                <Col>
                                    <Card>
                                        <label>Container Name</label>
                                        <Input name='name' />
                                        <label>Network name override</label>
                                        <Input name='data.networkOverride' />
                                        <label>Tag Type</label>
                                        <Input
                                            type='select'
                                            name='tagType'
                                            onChange={e => {
                                                state.setAtPath('tagType', e.target.value);
                                            }}
                                        >
                                            <option>- Select TagType -</option>
                                            <option value={'ac'}>Adnuntius Connect Tag</option>
                                            <option value={'legacy'}>Legacy</option>
                                        </Input>
                                    </Card>
                                </Col>
                            </Form>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <h2>Information</h2>
                                    <p>
                                        Container ID:
                                        <Highlight>{state.current.id}</Highlight>
                                    </p>
                                    <p>
                                        Last update:{' '}
                                        {moment
                                            .unix(state.current.updatedAt.substring(0, 10))
                                            .format('YYYY-MM-DD:')}{' '}
                                        {state.current.updatedBy ? `by:${state.current.updatedBy}` : ''}
                                    </p>
                                </Card>
                            </Col>
                        </Row>
                    </Block>
                </Col>
                <Button
                    loader={mutation.loading}
                    onClick={() => {
                        save({ variables: { containerData: clean(state.current) } });
                    }}
                >
                    Save
                </Button>
            </Row>
        </Container>
    );
};

export default Dashboard;
