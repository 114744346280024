import React, { useState } from 'react';
import { useStateHandler, clean } from 'utils';
import { Container, Col, Row } from 'reactstrap';
import { Form, Loader, Popup, ListItem, ItemSection, FilterGroup, FilterRow, Button } from 'components';
import { adunitList } from './Adunit';
import { renderDetails } from './Details';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import { GET_CONTAINER_PROFILE_BY_ID, UPDATE_CONTAINER } from 'requests/containers';
import shortid from 'shortid';
import { ContainerType } from 'interfaces/containers';
import { renderSystems } from './System';
import { renderAnalytics } from './Analytics';
import { NetworkProfile } from 'interfaces/networkProfile';
import { Site } from 'interfaces/sites';
import { notify } from 'react-notify-toast';

interface Request {
    getContainer: ContainerType;
    getNetworkProfile: NetworkProfile;
    getSites: Site[];
}

interface Update {
    containerData: ContainerType;
}

interface QueryVariables {
    id: string;
}

const newAdunit = () => {
    return {
        id: shortid.generate(),
        code: '',
        map: [],
        bids: [
            {
                id: shortid.generate(),
                active: true,
                bidder: '',
                params: {},
            },
        ],
        mediaTypes: {
            banner: { sizes: [] },
        },
    };
};

function PrebidView() {
    const { containerId } = useParams<{ containerId: string }>();
    const query = useQuery<Request, QueryVariables>(GET_CONTAINER_PROFILE_BY_ID, {
        variables: { id: containerId },
    });
    const state = useStateHandler<ContainerType>(query.data?.getContainer);
    const [save, mutation] = useMutation<Request, Update>(UPDATE_CONTAINER, {
        onCompleted: () => {
            notify.show('Item saved', 'success', 1000);
        },
    });

    const [editIndex, triggerEdit] = useState(0);
    const [tab, setTab] = useState('adunits');
    const [templates, toggleTemplates] = useState(false);
    const sizeTemplates = query.data?.getNetworkProfile?.profile?.sizeTemplates;
    const sites = query.data?.getSites;
    const prebid = state.current.prebid;

    if (query.loading || !prebid) return <Loader />;
    if (query.error) return <p>Error :(</p>;

    const addAdunit = () => {
        state.push('prebid.content', newAdunit());
    };

    const regenerateIds = () => {
        prebid.content.forEach((adunit, i) => {
            if (!adunit.id) {
                state.setAtPath(`prebid.content[${i}].id`, shortid.generate());
            }
        });
    };

    // Fix for older containers that may not contain a prebid requester
    if (!prebid.requester) {
        state.setAtPath('prebid.requester', { system: 'adnuntius' });
    }
    if (!prebid.analytics) {
        state.setAtPath('prebid.analytics', []);
    }

    return (
        <Container>
            <Form state={state}>
                {sizeTemplates ? (
                    <Popup show={templates} callback={() => toggleTemplates(false)}>
                        {sizeTemplates.map(item => {
                            return (
                                <ListItem key={item.id} onClick={() => state.concat(templates, item.sizes)}>
                                    <ItemSection>{item.name}</ItemSection>
                                </ListItem>
                            );
                        })}
                    </Popup>
                ) : (
                    <></>
                )}
                <Row>
                    <Col>
                        <h1>Prebid</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FilterRow>
                            <FilterGroup className='large'>
                                <button
                                    className={tab === 'adunits' ? 'active' : ''}
                                    onClick={() => {
                                        setTab('adunits');
                                    }}
                                >
                                    Ad units
                                </button>
                                <button
                                    className={tab === 'options' ? 'active' : ''}
                                    onClick={() => {
                                        setTab('options');
                                    }}
                                >
                                    Options
                                </button>
                                <button
                                    className={tab === 'analytics' ? 'active' : ''}
                                    onClick={() => {
                                        setTab('analytics');
                                    }}
                                >
                                    Analytics
                                </button>
                            </FilterGroup>
                        </FilterRow>
                    </Col>
                </Row>
                <hr />
                {tab === 'options' ? renderSystems(state, prebid, sites) : <></>}
                {tab === 'analytics' ? renderAnalytics(state) : <></>}
                {tab === 'adunits' ? (
                    <Row>
                        <Col md='5'>
                            {prebid?.content.map((adunit, i) =>
                                adunitList(state, adunit, i, editIndex, triggerEdit),
                            )}
                            <Button onClick={addAdunit}>Add Items</Button>
                            <p style={{ fontSize: '10px' }} onClick={regenerateIds}>
                                Regenerate IDs
                            </p>
                        </Col>
                        <Col md={7}>
                            {prebid.content[editIndex] &&
                                renderDetails(
                                    state,
                                    prebid,
                                    sites,
                                    prebid.content[editIndex],
                                    editIndex,
                                    toggleTemplates,
                                    sizeTemplates,
                                )}
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
                <div className='footer'>
                    <Button
                        loader={mutation.loading}
                        onClick={() => save({ variables: { containerData: clean(state.current) } })}
                    >
                        Save
                    </Button>
                </div>
            </Form>
        </Container>
    );
}

export default PrebidView;
