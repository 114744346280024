import React from 'react'
import { Block, Title, Content } from './style'

const BlockHolder = props => (
  <Block className={`${props.className}`} style={props.style} >
    {(props.title || props.headerArea) && <Title> 
      {props.title && props.title }
      {props.headerArea && props.headerArea}
    </Title> }
    <Content> {props.children} </Content>
  </Block>
)

export default BlockHolder
