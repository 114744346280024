import styled from 'styled-components';
import { colors, shadows } from 'style';

export const Holder = styled.div`
    background: ${colors.windowBackground};
    border: 1px solid ${props => (props.active ? colors.primary : 'rgba(0,0,0,.12)')};
    border-left: ${props => (props.active ? '5px solid ' + colors.primary : '1px solid rgba(0,0,0,.12)')};
    border-radius: 8px;
    margin-bottom: 15px;

    ${shadows.deep};

    /* 
    span {
        display: block;
        padding: 0 0 5px;
        width: 100%;
        font-size: 12px;
        color: ${colors.mediumGrey};
    } */

    &.active {
        border: 1px solid ${colors.primary};
        border-left: 5px solid ${colors.primary};
        box-shadow: ${colors.primary}b3 0px 3px 10px -4px;
    }

    button {
        margin-top: 20px;
    }

    ion-icon {
        cursor: pointer;
    }

    .headerButton {
        float: right;
        display: none;
    }
    &:hover .headerButton {
        display: block;
    }

    &.clickable {
        cursor: pointer;
    }
    &.warning div {
        color: rgba(0, 0, 0, 0.5);
    }
    &.warning {
        background-color: ${colors.warning};
    }
    &.info div {
        color: rgba(0, 0, 0, 0.5);
    }
    &.info {
        background-color: ${colors.info};
    }
`;

export const Title = styled.div`
    width: 100%;
    color: ${colors.darkGrey};
    padding: 10px 20px;
    font-weight: bold;
    border-bottom: 1px solid #eef0f3;

    input {
        padding: 2px 10px;
        margin: 0px -5px;
        border: 1px solid ${colors.lightGrey};
        border-radius: 4px;
        width: 100%;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
        background: ${colors.background};
    }

    button {
        margin: 0;
        background: ${colors.primary};
        color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 2px 10px;
        font-size: 12px;
        border-radius: 4px;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
    }
`;

export const Content = styled.div`
    width: 100%;
    padding: 20px;
    /* overflow: auto; */
    .form-group {
        margin-bottom: 3px;
    }
`;

export const SimpleCard = styled(Holder)`
    padding: 20px;
    overflow: hidden;
`;
