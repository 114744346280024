import React from 'react';
import { client } from 'apolloClient';
import { FilterGroup, FilterRow } from 'components';
import { gql } from '@apollo/client';

const Filters = props => {
    const clickItem = clickedType => {
        window.localStorage.setItem('stateFilter', clickedType);

        client.writeQuery({
            query: gql`
                query Cache {
                    stateFilter
                }
            `,
            data: {
                stateFilter: clickedType,
            },
        });
    };

    return (
        <FilterRow>
            <FilterGroup>
                <button
                    className={props.state === 'active' ? 'active' : ''}
                    onClick={() => {
                        clickItem('active');
                    }}
                >
                    Active
                </button>
                <button
                    className={props.state === 'deleted' ? 'active' : ''}
                    onClick={() => {
                        clickItem('deleted');
                    }}
                >
                    Deleted
                </button>
            </FilterGroup>
        </FilterRow>
    );
};

export default Filters;
