import React, { useState } from 'react';
import { DropDownHolder, UserItem } from './style';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useHistory } from 'react-router';

const Network = props => {
    const history = useHistory();
    const [dropDown, setDropDown] = useState(false);

    return (
        <DropDownHolder>
            <Dropdown
                isOpen={dropDown}
                toggle={() => {
                    setDropDown(!dropDown);
                }}
            >
                <DropdownToggle
                    tag='div'
                    onClick={() => {
                        setDropDown(!dropDown);
                    }}
                    data-toggle='dropdown'
                    aria-expanded={dropDown}
                >
                    <UserItem>{props.user.displayName.charAt(0).toUpperCase()}</UserItem>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem
                        onClick={() => {
                            history.push('/network');
                        }}
                    >
                        Settings
                    </DropdownItem>
                    <DropdownItem header>User</DropdownItem>
                    <DropdownItem
                        onClick={() => {
                            props.client.resetStore();
                            history.push('/login');
                        }}
                    >
                        Logout
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </DropDownHolder>
    );
};

export default Network;
