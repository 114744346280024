import React from 'react';

import { Col, FormGroup, Label } from 'reactstrap';
import { Input, Card } from 'components';
import { State } from 'interfaces/state';

interface Props {
    state?: State;
}

export default function PixelTag({ state }: Props) {
    return (
        <>
            <Card>
                <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={2}>
                        Pixel Url
                    </Label>
                    <Col sm={8}>
                        <Input state={state} name='content.url' />
                    </Col>
                </FormGroup>
            </Card>
        </>
    );
}
