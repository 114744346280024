import { gql } from '@apollo/client';
import { Language } from 'interfaces/languages';

export const GET_LANGUAGE_BY_ID = gql`
    query getLanguage($id: ID) {
        stateFilter @client @export(as: "state")
        network @client
        getLanguage(id: $id) {
            id
            name
            state
            languageCode
            texts
        }
        getPurposes(filter: { state: active }) {
            id
            name
            externalId
        }
    }
`;

export const GET_LANGUAGES = gql`
    query getLanguages($state: State) {
        stateFilter @client @export(as: "state")
        network @client
        getLanguages(filter: { state: $state }) {
            id
            name
            state
            languageCode
            texts
        }
    }
`;

export const UPDATE_LANGUAGE = gql`
    mutation($languageData: [LanguageInput]) {
        editLanguages(languageData: $languageData) {
            id
            name
            state
            languageCode
            texts
        }
    }
`;

export const newItem = (name, containerArray): Language => {
    return {
        name: name,
        state: 'active',
        languageCode: 'en',
        texts: {},
        add: {
            containers: containerArray,
        },
    };
};
