import { gql } from '@apollo/client';
import { User } from 'interfaces/users';

export const GET_USERS = gql`
    query getUsers {
        network @client
        getUsers {
            id
            name
            roles
        }
    }
`;

export const GET_USER_BY_ID = gql`
    query getUsers($id: ID) {
        network @client
        getUsers(id: $id) {
            id
            name
            roles
            containerPermissions
        }
        getContainers(filter: { state: active }) {
            id
            name
            state
        }
    }
`;

export const UPDATE_USER = gql`
    mutation editUsers($userData: UserInput) {
        editUsers(userData: $userData) {
            id
            name
            roles
            containerPermissions
        }
    }
`;
