import React from 'react';
import { Container } from 'reactstrap';
import { GET_TRIGGER_LIST, UPDATE_TRIGGER, GET_GLOBAL_TRIGGERS, newTrigger } from 'requests/triggers';
import { Trigger } from 'interfaces/triggers';
import { useList } from 'utils/useList';
import { useParams } from 'react-router';
import Edit from './Edit';
import { SlideOverlay } from 'components';
import AddRemoveGlobalsView from 'containers/AddRemoveGlobals';

export interface Request {
    getTriggers: Trigger[];
}
export interface Mutation {
    triggerData: Trigger;
}

function TriggersView() {
    const { id } = useParams<{ id: string }>();
    const list = useList<Request, Mutation, Trigger>({
        parent: 'containers',
        type: 'trigger',
        request: GET_TRIGGER_LIST,
        mutation: UPDATE_TRIGGER,
        createItem: newTrigger,
        headers: [
            { name: 'name', label: 'Name' },
            { name: 'trigger', label: 'Trigger' },
        ],
    });

    return (
        <Container>
            <h1>Triggers</h1>
            {list}
            <AddRemoveGlobalsView<Request>
                type={'trigger'}
                current={GET_TRIGGER_LIST}
                mutation={UPDATE_TRIGGER}
                request={GET_GLOBAL_TRIGGERS}
            />
            {id && (
                <SlideOverlay size='large'>
                    <Edit id={id} />
                </SlideOverlay>
            )}
        </Container>
    );
}

export default TriggersView;
