const analytics = {
    pubwise: {
        provider: 'pubwise',
        options: {
            site: '',
            endpoint: 'https://api.pubwise.io/api/v4/event/add/',
        },
    },
    pubxai: {
        provider: 'pubxai',
        options: {
            samplingRate: 1,
            pubxId: '',
        },
    },
};

export default analytics;
