import { useParams } from 'react-router';
import logo from 'assets/img/mainLogo.png';
import {
    analyticsOutline,
    fileTrayFullOutline,
    cloudUploadOutline,
    colorWandOutline,
    hammerOutline,
    pricetagOutline,
    lockOpenOutline,
    albumsOutline,
    enterOutline,
    personOutline,
} from 'ionicons/icons';

const getEnvironment = () => {
    if (window.location.host.indexOf('localhost:3000') > -1) return 'dev';
    else if (window.location.host.indexOf('staging') > -1) return 'staging';
    else return 'production';
};

export const env = {
    environment: getEnvironment(),
    dev: {
        urls: {
            api: 'http://localhost:3001',
        },
    },

    staging: {
        urls: {
            api: 'https://connect-api.staging.adnuntius.com',
        },
    },

    production: {
        urls: {
            api: 'https://connect-api.adnuntius.com',
        },
    },
};

export const useConfig = () => {
    const { containerId } = useParams<{ containerId: string }>();

    const config = {
        appName: 'Connect',
        environment: getEnvironment(),

        env: {
            dev: {
                urls: {
                    api: 'http://localhost:3001',
                },
            },

            staging: {
                urls: {
                    api: 'https://connect-api.staging.adnuntius.com',
                },
            },

            production: {
                urls: {
                    api: 'https://connect-api.adnuntius.com',
                },
            },
        },

        poweredBy: {
            name: 'Adnuntius',
            link: 'https://adnuntius.com',
        },

        logo: {
            img: logo,
        },

        menu: {
            network: [
                {
                    name: 'Dashboard',
                    items: [
                        {
                            icon: analyticsOutline,
                            link: `/network/dashboard`,
                            label: 'Dashboard',
                        },
                    ],
                },
                {
                    name: 'Network',
                    items: [
                        {
                            icon: albumsOutline,
                            link: '/network/sizeTemplates',
                            label: 'Size Templates',
                        },
                        // {
                        //     icon: enterOutline,
                        //     link: '/network/bidders',
                        //     label: 'Bidders',
                        // },
                    ],
                },
                {
                    name: 'Privacy',
                    items: [
                        {
                            icon: enterOutline,
                            link: '/network/purposes',
                            label: 'Purposes',
                        },
                        {
                            icon: enterOutline,
                            link: '/network/languages',
                            label: 'Languages',
                        },
                    ],
                },
                {
                    name: 'Globals',
                    items: [
                        {
                            icon: colorWandOutline,
                            link: `/network/variables`,
                            label: 'Variables',
                        },

                        {
                            icon: hammerOutline,
                            link: `/network/triggers`,
                            label: 'Triggers',
                        },

                        {
                            icon: pricetagOutline,
                            link: `/network/tags`,
                            label: 'Tags',
                        },
                    ],
                },
                // {
                //     name: 'Publish',
                //     permission: ['MANAGE_SYSTEM'],
                //     items: [
                //         {
                //             icon: cloudUploadOutline,
                //             link: `/network/publish`,
                //             label: 'Publish',
                //         },
                //     ],
                // },
            ],
            containers: [
                {
                    name: 'Dashboard',
                    items: [
                        {
                            icon: analyticsOutline,
                            link: `/containers/${containerId}/dashboard`,
                            label: 'Dashboard',
                        },
                    ],
                },

                {
                    name: 'Standard',
                    permission: ['admin'],
                    items: [
                        {
                            icon: lockOpenOutline,
                            link: `/containers/${containerId}/privacy`,
                            label: 'Privacy',
                        },
                        {
                            icon: fileTrayFullOutline,
                            link: `/containers/${containerId}/prebid`,
                            label: 'Prebid',
                        },
                    ],
                },

                {
                    name: 'Tags',
                    items: [
                        {
                            icon: colorWandOutline,
                            link: `/containers/${containerId}/variables`,
                            label: 'Variables',
                        },

                        {
                            icon: hammerOutline,
                            link: `/containers/${containerId}/triggers`,
                            label: 'Triggers',
                        },

                        {
                            icon: pricetagOutline,
                            link: `/containers/${containerId}/tags`,
                            label: 'Tags',
                        },
                    ],
                },

                {
                    name: 'Publish',
                    permission: ['admin'],
                    items: [
                        {
                            icon: cloudUploadOutline,
                            link: `/containers/${containerId}/publish`,
                            label: 'Publish',
                        },
                    ],
                },
            ],
            admin: [
                {
                    name: 'Dashboard',
                    items: [
                        {
                            icon: analyticsOutline,
                            link: `/admin/dashboard`,
                            label: 'Dashboard',
                        },
                    ],
                },
                {
                    name: 'Users',
                    items: [
                        {
                            icon: personOutline,
                            link: `/admin/users`,
                            label: 'Users',
                        },
                    ],
                },
            ],
        },
    };

    return config;
};
