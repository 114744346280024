import React, { useState } from 'react';
import { DropDownHolder, NetworkSelector } from './style';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ContainerType } from 'interfaces/containers';
import { User } from 'interfaces/uiInterfaces';
import { useHistory } from 'react-router';

interface Props {
    data: {
        getContainers: ContainerType[];
        getUser: User;
    };
    containerId: string;
}

const Containers = ({ data, containerId }: Props) => {
    const [dropDown, toggleDropdown] = useState(false);
    const history = useHistory();

    const currentContainer = data ? data.getContainers.find(item => item.id === containerId) : false;

    const onClick = clickedId => {
        window.localStorage.setItem('containerId', clickedId);
        history.push(`/containers/${clickedId}/dashboard`);
    };

    if (data.getContainers) {
        return (
            <DropDownHolder>
                <Dropdown isOpen={dropDown} toggle={() => toggleDropdown(!dropDown)}>
                    <DropdownToggle tag='div' data-toggle='dropdown' aria-expanded={dropDown}>
                        <NetworkSelector>
                            {currentContainer ? currentContainer.name : 'No containers'}
                        </NetworkSelector>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem header>Containers</DropdownItem>
                        {data.getContainers.map(container => {
                            return (
                                <DropdownItem
                                    key={'select_' + container.id}
                                    className={container.id === containerId ? 'active' : ''}
                                    onClick={() => {
                                        onClick(container.id);
                                    }}
                                >
                                    {container.name}
                                </DropdownItem>
                            );
                        })}
                    </DropdownMenu>
                </Dropdown>
            </DropDownHolder>
        );
    } else return <></>;
};

export default Containers;
