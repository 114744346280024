import React from "react"
import Prism from "prismjs"
import { CodeHolder } from 'style'
import './ghcolors.css'


export default class PrismCode extends React.Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef()
    }
    componentDidMount() {
        this.highlight()
    }
    componentDidUpdate() {
        this.highlight()
    }
    highlight() {
        if (this.ref && this.ref.current) {
            Prism.highlightElement(this.ref.current)
        }
    }
    render() {
        const { code, language } = this.props
        return (<CodeHolder>
            {(this.props.title) ? <div className="header">{this.props.title}</div> : ''}
            <pre className={'line-numbers'}>
                <code ref={this.ref} className={`language-${language}`}>
                    {code.trim()}
                </code>
            </pre>
        </CodeHolder>)
    }
}