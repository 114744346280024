import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { Card, Input, CustomInput } from 'components';
import { State } from 'interfaces/state';

interface Props {
    state: State;
}

export default function GoogleAnalytics({ state }: Props) {
    return (
        <>
            <Card>
                <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={2}>
                        Tag Id
                    </Label>
                    <Col sm={8}>
                        <Input state={state} name='content.tagId' />
                    </Col>
                </FormGroup>
                <Row>
                    <Col>
                        <CustomInput
                            state={state}
                            type='switch'
                            id='disablePageView'
                            name='content.disablePageView'
                            label='Disable Page View'
                        />
                    </Col>
                </Row>
            </Card>
        </>
    );
}
