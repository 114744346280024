import React from 'react';
import { FormGroup, Label, Col } from 'reactstrap';
import { RemoveButton, AddButton, Input, CustomInput } from 'components';
import shortid from 'shortid';
import { Bidders, Bidder, BidderLeft, BidderRight } from './style';
import bidders from './analytics_config';

export const renderAnalytics = state => {
    const analytics = state.current.prebid.analytics;
    return (
        <>
            <h2>Bidders</h2>
            <Bidders>
                {analytics.map((analyticSystem, itemIndex) => {
                    const analyticsName = `prebid.analytics[${itemIndex}]`;
                    return (
                        <Bidder key={analyticSystem.id}>
                            <BidderLeft>
                                <FormGroup>
                                    <CustomInput
                                        defaultChecked={true}
                                        type='switch'
                                        id={`${itemIndex}-active`}
                                        name={`${analyticsName}.active`}
                                        label='Active'
                                    />
                                </FormGroup>
                                <Input
                                    type='select'
                                    name={`${analyticsName}.provider`}
                                    hook={e => {
                                        const data = e.target.options[e.target.selectedIndex].dataset;

                                        state.setAtPath(`${analyticsName}`, {
                                            active: analyticSystem.active,
                                            provider: e.target.value,
                                            options: bidders[data.name || e.target.value].options,
                                        });
                                    }}
                                >
                                    <option value='' disabled>
                                        - Analytics Module -
                                    </option>
                                    {Object.keys(bidders).map(bidderName => (
                                        <option key={'bidder_' + itemIndex + bidderName} value={bidderName}>
                                            {bidderName}
                                        </option>
                                    ))}
                                </Input>
                            </BidderLeft>

                            <BidderRight>
                                {Object.keys(analyticSystem.options).map(option => {
                                    return (
                                        <FormGroup key={'bidder_' + option} row>
                                            <Label style={{ textAlign: 'right' }} sm={4}>
                                                {option}
                                            </Label>
                                            <Col sm={7}>
                                                <Input name={`${analyticsName}.options.${option}`} />
                                            </Col>
                                        </FormGroup>
                                    );
                                })}
                            </BidderRight>

                            <RemoveButton onClick={() => state.splice(`prebid.analytics`, itemIndex)} />
                        </Bidder>
                    );
                })}
            </Bidders>
            <AddButton
                size='small'
                onClick={() =>
                    state.push(`prebid.analytics`, {
                        id: shortid.generate(),
                        active: true,
                        provider: '',
                        options: {},
                    })
                }
            />
        </>
    );
};
