import React from 'react';
import { Container, Row } from 'reactstrap';

const Dashboard = () => {
    return (
        <Container>
            <Row>
                <h1>Admin Dashboard</h1>
            </Row>
        </Container>
    );
};

export default Dashboard;
