/*

Could need to push state to the lower elements. 
Form element just sets state to it's closest childern

*/

import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useLocation, useParams } from 'react-router';
import { useStateHandler } from 'utils/stateHandler';
import { notify } from 'react-notify-toast';
import { Form, Input, Button, Card, Loader } from 'components';
import { Container, Row, Col } from 'reactstrap';
import Select from 'react-select';
import { customTagStyles } from 'style';
import defaults from './tags/defaults';
import Tags from './tags';
import { clean } from 'utils';
import { UPDATE_TAGS, GET_TAG_BY_ID } from 'requests/tags';
import { Tag } from 'interfaces/tags';
import { Trigger } from 'interfaces/triggers';
import { Purpose } from 'interfaces/purposes';

interface Props {
    id: string;
}
interface QueryVariables {
    containerId: string;
    id: string;
}

interface Update {
    tagData: Tag;
}
interface Request {
    getTriggers: Trigger[];
    getPurposes: Purpose[];
    getTags: Tag[];
    getTag: Tag;
}

export default function TagsEdit({ id }: Props) {
    // const request = Tag.useRequest({ id: id });
    const { containerId } = useParams<{ containerId: string }>();
    const isNetworkEdit = useLocation().pathname.includes('network');

    const query = useQuery<Request, QueryVariables>(GET_TAG_BY_ID, {
        variables: { id: id, containerId: containerId },
    });
    const state = useStateHandler<Tag>(query.data?.getTag);
    const [save, mutation] = useMutation<Request, Update>(UPDATE_TAGS, {
        refetchQueries: [GET_TAG_BY_ID],
        onCompleted: () => {
            notify.show('Item saved', 'success', 1000);
        },
    });
    if (query.loading || !state.current.id) return <Loader />;
    if (query.error) return <p>Error :(</p>;

    const reshapeOptions = item => {
        return { value: item.id, label: item.name };
    };

    const optionsTriggers = query.data?.getTriggers.map(reshapeOptions);
    const optionsPurposes = query.data?.getPurposes.map(reshapeOptions);
    const initialTriggers = state.current.triggers.map(item => item.id);

    console.log(initialTriggers);
    const change = val => {
        state.update({
            type: val.target.value,
            content: defaults[val.target.value].content,
        });
    };

    return (
        <Container>
            {initialTriggers.length === 0 && (
                <Card type={'warning'}>A tag requires a trigger to be valid.</Card>
            )}
            <Form state={state}>
                <Card>
                    <label>Tag Name</label>
                    <Input name='name' />
                </Card>
                {!isNetworkEdit ? (
                    <Row>
                        <Col md='6'>
                            <Card title='Triggers'>
                                <Select
                                    defaultValue={state.current.triggers?.map(reshapeOptions)}
                                    isMulti
                                    styles={customTagStyles}
                                    options={optionsTriggers}
                                    onChange={vals => {
                                        const updatedTriggers = vals
                                            ? vals.map(trigger => trigger.value)
                                            : [];
                                        const remove = {
                                            triggers: initialTriggers.filter(
                                                triggerId => !updatedTriggers.includes(triggerId),
                                            ),
                                        };
                                        const add = {
                                            triggers: updatedTriggers.filter(
                                                triggerId => !initialTriggers.includes(triggerId),
                                            ),
                                        };

                                        state.setMultipleAtPath([{ add: add }, { remove: remove }]);
                                    }}
                                />
                            </Card>
                        </Col>
                        <Col md='6'>
                            <Card title='Purposes'>
                                <Select
                                    defaultValue={state.current.purposes?.map(reshapeOptions)}
                                    isMulti
                                    styles={customTagStyles}
                                    options={optionsPurposes}
                                    onChange={vals => {
                                        const set = vals ? vals.map(purpose => purpose.value) : [];
                                        state.setAtPath(`assignTo.purposes`, set);
                                    }}
                                />
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}

                <Card>
                    <label>Tag Type</label>
                    <Input type='select' name='type' onChange={change}>
                        {Object.keys(defaults).map(type => (
                            <option key={'select_' + type} value={type}>
                                {defaults[type].name}
                            </option>
                        ))}
                    </Input>
                </Card>

                <Tags state={state} />

                <Button
                    style={{ position: 'sticky' }}
                    loader={mutation.loading}
                    onClick={() => {
                        save({ variables: { tagData: clean(state.current) } });
                        // request.save(clean(state.current), () => {
                        //     request.refetch();
                        // });
                    }}
                >
                    Save
                </Button>
            </Form>
        </Container>
    );
}
