import React from 'react';
import { Form, Input, Button, Loader, CustomInput, Card } from 'components';
import { useQuery, useMutation } from '@apollo/client';
import { Container, Label, Row, Col } from 'reactstrap';
import { Purpose } from 'interfaces/purposes';
import { GET_PURPOSE_BY_ID, UPDATE_PURPOSE } from 'requests/purposes';
import { notify } from 'react-notify-toast';
import { clean, useStateHandler } from 'utils';

interface Props {
    id: string;
}

export interface MutateVariables {
    purposeData: Purpose;
}
export interface RequestVariable {
    getPurpose: Purpose;
}

export default function PurposeEdit({ id }: Props) {
    const query = useQuery<RequestVariable, Props>(GET_PURPOSE_BY_ID, { variables: { id: id } });
    const state = useStateHandler<Purpose>(query.data?.getPurpose);
    const [save, mutation] = useMutation<RequestVariable, MutateVariables>(UPDATE_PURPOSE, {
        onCompleted: () => {
            notify.show('Item saved', 'success', 1000);
        },
    });

    if (query.loading || !state.current) return <Loader />;
    if (query.error) return <p>Error :(</p>;

    return (
        <Container>
            <Form state={state}>
                <Card>
                    <Row>
                        <Col>
                            <label>Purpose Name</label>
                            <Input name='name' />
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <CustomInput type='switch' id='forced' name='forced' label='Forced Purpose' />
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md='6'>
                            <Label>External Id</Label>
                            <Input name='externalId' />
                        </Col>
                        <Col md='6'>
                            <Label>Adnuntius Purpose</Label>
                            <Input type='select' name='adnuntiusPurpose'>
                                <option>- Select Purpose -</option>
                                <option value='PROFILE'>PROFILE</option>
                                <option value='COUNTS'>COUNTS</option>
                                <option value='EXTERNAL'>EXTERNAL</option>
                                <option value='TARGETING'>TARGETING</option>
                            </Input>
                        </Col>
                    </Row>
                </Card>

                <div className='footer'>
                    <Button
                        loader={mutation.loading}
                        onClick={() => {
                            save({ variables: { purposeData: clean(state.current) } });
                        }}
                    >
                        Save
                    </Button>
                </div>
            </Form>
        </Container>
    );
}
