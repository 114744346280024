import { gql } from '@apollo/client';

export const GET_PREBID_BIDDER_BY_ID = gql`
    query($id: ID) {
        getPrebidBidder(id: $id) {
            id
            name
            bidderCode
            params
        }
    }
`;

export const GET_PREBID_BIDDERS = gql`
    query {
        getPrebidBidders {
            id
            name
            bidderCode
        }
    }
`;
