import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Notifications from 'react-notify-toast';
import { client } from './apolloClient';
import { ApolloProvider, useQuery } from '@apollo/client';
import { Theme } from 'style';
import { gql } from '@apollo/client';
import Login from 'views/Login';
import Dashboard from 'views/Containers/Dashboard';
import Publish from 'views/Containers/Publish';
import Prebid from 'views/Containers/Prebid';
import Privacy from 'views/Containers/Privacy';
import Main from 'containers/Main';
import Containers from 'views/Containers/Containers';

import Triggers from 'views/Containers/Triggers';
import Tags from 'views/Containers/Tags';
import Variables from 'views/Containers/Variables';
import SizeTemplates from 'views/Network/SizeTemplates';
import Purpose from 'views/Network/Purposes';
import Language from 'views/Network/Languages';
import NetworkVariables from 'views/Network/Variables';
import NetworkTriggers from 'views/Network/Triggers';
import NetworkTags from 'views/Network/Tags';
import NetworkDashboard from 'views/Network/Dashboard';
import NetworkPublish from 'views/Network/Publish';
import NetworkBidders from 'views/Network/Bidders';
import AdminDashboard from 'views/Admin/Dashboard';
import AdminContainerCopy from 'views/Admin/ContainerCopy';
import Users from 'views/Admin/Users';

const CACHE_GET = gql`
    query getCacheFromClient {
        stateFilter @client
        containerId @client
        token @client
        user @client
    }
`;

const SecureRoute = ({ component: Component, ...props }) => {
    const { data } = useQuery(CACHE_GET, {
        onCompleted: () => {},
    });
    const items = props.items;

    return data.token ? (
        <Route
            {...props}
            render={prop => {
                return (
                    <Main items={items}>
                        <Component {...prop} />
                    </Main>
                );
            }}
        />
    ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    );
};

const Routes = () => {
    return (
        <ApolloProvider client={client}>
            <Theme>
                <Router>
                    <Notifications />
                    <Switch>
                        {/* NETWORK ROUTES */}

                        <SecureRoute
                            exact
                            path={['/network', '/network/dashboard']}
                            component={NetworkDashboard}
                        />
                        <SecureRoute exact path={['/network/sizeTemplates']} component={SizeTemplates} />
                        <SecureRoute
                            exact
                            path={['/network/purposes', '/network/purposes/:id']}
                            component={Purpose}
                        />
                        <SecureRoute
                            exact
                            path={['/network/languages', '/network/languages/:id']}
                            component={Language}
                        />
                        <SecureRoute
                            exact
                            path={['/network/variables', '/network/variables/:id']}
                            component={NetworkVariables}
                        />
                        <SecureRoute
                            exact
                            path={['/network/triggers', '/network/triggers/:id']}
                            component={NetworkTriggers}
                        />
                        <SecureRoute
                            exact
                            path={['/network/tags', '/network/tags/:id']}
                            component={NetworkTags}
                        />
                        <SecureRoute
                            exact
                            path={['/network/bidders', '/network/bidders/:id']}
                            component={NetworkBidders}
                        />
                        <SecureRoute exact path='/network/publish' component={NetworkPublish} />

                        <SecureRoute exact path='/containers' component={Containers} items={['no-sidebar']} />
                        <SecureRoute exact path='/containers/:containerId/dashboard' component={Dashboard} />
                        <SecureRoute exact path='/containers/:containerId/publish' component={Publish} />
                        <SecureRoute exact path={['/containers/:containerId/prebid']} component={Prebid} />
                        <SecureRoute exact path={['/containers/:containerId/privacy']} component={Privacy} />
                        <SecureRoute
                            exact
                            path={['/containers/:containerId/copy']}
                            component={AdminContainerCopy}
                        />
                        <SecureRoute
                            exact
                            path={['/containers/:containerId/tags', '/containers/:containerId/tags/:id']}
                            component={Tags}
                        />
                        <SecureRoute
                            exact
                            path={[
                                '/containers/:containerId/variables',
                                '/containers/:containerId/variables/:id',
                            ]}
                            component={Variables}
                        />
                        <SecureRoute
                            exact
                            path={[
                                '/containers/:containerId/triggers',
                                '/containers/:containerId/triggers/:id',
                            ]}
                            component={Triggers}
                        />
                        <SecureRoute exact path={['/admin', '/admin/dashboard']} component={AdminDashboard} />
                        <SecureRoute exact path={['/admin/users', '/admin/users/:id']} component={Users} />

                        <Route exact path='/login' component={Login} />
                        <Route exact path='/' component={Login} />
                        <Route render={() => <div>404!!!</div>} />
                    </Switch>
                </Router>
            </Theme>
        </ApolloProvider>
    );
};

export default Routes;
