import styled from 'styled-components';
import { colors } from 'style';

export const AuthContainer = styled.div`
    width: 90%;
    position: relative;
    max-width: 500px;
    min-height: 300px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    margin: auto;
    box-shadow: rgba(74, 87, 99, 0.35) 0px 24px 40px -20px, inset 0px 0px 0px 1px rgba(0, 0, 0, 0.18);
`;

export const LogoHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 30px;
    background-color: ${colors.primary};
    left: 0;
    text-align: center;

    h1 {
        width: 100%;
        color: #fff;
        margin: 0;
    }
    p {
        color: white;
        width: 100%;
        font-weight: 400;
    }

    @media (max-width: 1000px) {
        width: 100%;
        height: 100px;
        position: relative;
    }
`;
export const FormHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    padding: 40px;

    a {
        font-weight: 400;
        color: ${colors.lightGrey};
    }
    a:hover {
        color: ${colors.primary};
    }

    @media (max-width: 1000px) {
        width: 100%;
        position: relative;
    }
`;

export const AuthScreen = styled.div`
    background: #eceef4;
    height: 100%;
    padding: 10% 0px;
    img {
        max-width: 200px;
        opacity: 0.1;
        display: block;
        margin: auto;
    }
`;

export const Footer = styled.div`
    position: fixed;
    bottom: 0;
`;
