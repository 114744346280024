import React from 'react';
import { Input as InputStrap, CustomInput as CustomInputStrap } from 'reactstrap';
import { SelectListHolder } from './style';
import { CodeHolder } from 'style';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import { State } from 'interfaces/state';
import variableHint from 'utils/variableHint';
function inheritState(props) {
    const childMap = React.Children.map(props.children, child => {
        const newChild = { ...child };

        if (newChild.props?.children) {
            if (typeof newChild.props.children === 'object') {
                const newProps = { ...newChild.props, state: props.state };
                newProps.children = inheritState(newProps);
                newChild.props = { ...newProps };
            }
        }

        if (newChild !== null && newChild.type !== 'button') {
            return React.cloneElement(newChild, {
                state: { ...props.state },
            });
        } else return newChild;
    });
    return childMap;
}

export function Form(props) {
    const children = inheritState(props);
    return (
        <form
            {...props}
            onSubmit={e => {
                e.preventDefault();
                if (props.onSubmit) props.onSubmit(e);
            }}
        >
            {children}
        </form>
    );
}

interface InputProps {
    children?: React.ReactNode;
    state?: State;
    name: string;
    type?: string;
    placeholder?: string;
    defaultValue?;
    hook?;
    onChange?;
    autoFocus?;
    required?;
    disabled?: boolean;
}

interface CustomInputProps {
    id;
    label: string;
    state?: State;
    name: string;
    type?: string;
    disabled?: boolean;
    defaultChecked?: boolean;
    onChange?: (e: any) => void;
}

interface SelectListProps {
    label: string;
    key?: string;
    state?: State;
    name: string;
}

interface CodeProps {
    state?: State;
    name: string;
    type: 'css' | 'html' | 'javascript';
}

export function SelectList({ state, name, label }: SelectListProps) {
    let defaultValue = state?.getValueFrom(name);
    if (defaultValue === '') {
        defaultValue = false;
    }

    return (
        <SelectListHolder
            className={defaultValue ? 'active' : ''}
            style={{ margin: '10px 0px' }}
            onClick={e => {
                state?.setAtPath(name, !defaultValue);
            }}
        >
            {label}
        </SelectListHolder>
    );
}

export function CustomInput({
    id,
    state,
    name,
    type,
    label,
    defaultChecked,
    disabled,
    onChange,
}: CustomInputProps) {
    return (
        <div style={{ margin: '10px 0px' }}>
            <CustomInputStrap
                disabled={disabled}
                id={id}
                type={type}
                name={name}
                label={label}
                onChange={e => {
                    state?.change(e);
                    if (onChange) onChange(e);
                }}
                checked={state?.getValueFrom(name)}
                // defaultChecked={state?.getValueFrom(name)}
            />
        </div>
    );
}

export function Code({ state, name, type }: CodeProps) {
    const defaultValue = state?.getValueFrom(name);
    const highlight = code => {
        return Prism.highlight(code, Prism.languages[type], type);
    };
    return (
        <CodeHolder>
            <div className='header'>Css</div>
            <Editor
                value={defaultValue || ''}
                name='css'
                tabSize={4}
                onChange={e => variableHint(e.target)}
                onValueChange={code => {
                    state?.setAtPath(name, code);
                }}
                highlight={highlight}
                padding={10}
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 14,
                }}
            />
        </CodeHolder>
    );
}

export function Input({
    children,
    state,
    name,
    type,
    placeholder,
    defaultValue,
    hook,
    onChange,
    autoFocus,
    required,
    disabled,
}: InputProps) {
    const defValue =
        type === 'number'
            ? parseInt(defaultValue || state?.getValueFrom(name))
            : String(defaultValue || state?.getValueFrom(name));

    return (
        <InputStrap
            required={required}
            placeholder={placeholder}
            type={type}
            name={name}
            disabled={disabled}
            autoFocus={autoFocus}
            onChange={e => {
                variableHint(e.target);
                if (onChange) onChange(e);
                else state?.change(e, type, hook);
            }}
            // defaultValue={undefined}
            value={defValue || ''}
        >
            {children}
        </InputStrap>
    );
}
