import React from 'react';
import { AppinfoHolder } from './style';
import packageJson from '../../../package.json';
import { useConfig } from 'config';

const AppInfo = () => {
    const config = useConfig();
    return (
        <AppinfoHolder>
            Version: {packageJson.version}
            <span>|</span>
            Powered by: <a href={config.poweredBy.link}>{config.poweredBy.name}</a>
        </AppinfoHolder>
    );
};

export default AppInfo;
