import { gql } from '@apollo/client';
import { Variable } from 'interfaces/variables';

export const GET_VARIABLE_BY_ID = gql`
    query getVariable($id: ID) {
        network @client
        getVariable(id: $id) {
            id
            name
            state
            type
            variable
            default
            global
        }
    }
`;

export const GET_VARIABLES_LIST = gql`
    query getVariableList($state: State, $containerId: String) {
        stateFilter @client @export(as: "state")
        network @client
        getVariables(filter: { state: $state, containerId: $containerId }) {
            id
            name
            state
            type
            variable
            global
            default
        }
    }
`;

export const GET_GLOBAL_VARIABLES = gql`
    query getVariableList($state: State) {
        stateFilter @client @export(as: "state")
        network @client
        getVariables(filter: { state: $state, global: true }) {
            id
            name
            state
            type
            variable
            default
            global
        }
    }
`;

export const UPDATE_VARIABLE = gql`
    mutation editVariables($variableData: [VariableInput]) {
        editVariables(variableData: $variableData) {
            id
            name
            state
            type
            variable
            default
            global
        }
    }
`;

export const newVariable = (name: string, containerArray: string[], global: boolean = false): Variable => {
    const variable = {
        name: name,
        state: 'active',
        type: 'data',
        default: '',
        variable: '',
    };
    if (containerArray) {
        variable['add'] = {
            containers: containerArray,
        };
    }
    if (global) {
        variable['global'] = true;
    }
    return variable;
};
