import React, { useEffect, useState, useRef } from 'react';
import { Holder, Overlay } from './style';
import { useKeyPress } from 'utils';

const Popup = ({ children, callback, show }) => {
    const [shouldRender, setRender] = useState(false);
    const [shouldShow, setShow] = useState(false);
    const wrapperRef = useRef();
    useKeyPress('Escape', () => {
        setShow(false);
    });

    function handleClickOn(event) {
        if (wrapperRef.current && wrapperRef.current === event.target) {
            setShow(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOn);
        return () => {
            document.removeEventListener('mousedown', handleClickOn);
        };
    });

    useEffect(() => {
        setShow(show);
    }, [show]);

    useEffect(() => {
        if (shouldShow) setRender(true);
    }, [shouldShow]);

    const onAnimationEnd = () => {
        if (!shouldShow) {
            setRender(false);
            if (callback) callback();
        }
    };

    return shouldRender ? (
        <Overlay
            ref={wrapperRef}
            style={{ animation: `${shouldShow ? 'fadeIn' : 'fadeOut'} .2s` }}
            onAnimationEnd={onAnimationEnd}
        >
            <Holder style={{ animation: `${shouldShow ? 'scaleIn' : 'scaleOut'} .2s` }}>
                <ion-icon name='close' onClick={() => setShow(false)}></ion-icon>
                {children}
            </Holder>
        </Overlay>
    ) : (
        <></>
    );
};

export default Popup;
