import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { CodeHolder } from 'style';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import { Card, Input, Highlight } from 'components';
import { State } from 'interfaces/state';

interface Props {
    state: State;
}

export default function ZummyTag({ state }: Props) {
    const current = state.current.content;
    return (
        <>
            <Card>
                <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={2}>
                        Target ID
                    </Label>
                    <Col sm={8}>
                        <Input
                            state={state}
                            name='content.targetId'
                            defaultValue={current.targetId || 'recs_' + state.current.id}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={2}>
                        Customer Id
                    </Label>
                    <Col sm={8}>
                        <Input state={state} name='content.customerId' />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={2}>
                        Type
                    </Label>
                    <Col sm={8}>
                        <Input state={state} type='select' name={`content.type`}>
                            <option value='summary'>Recommendations</option>
                            <option value='affiliate'>Affiliate</option>
                        </Input>
                    </Col>
                </FormGroup>
                <hr />
                <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={2}>
                        Number of recs
                    </Label>
                    <Col sm={8}>
                        <Input state={state} name='content.size' />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={2}>
                        Lookback Hours
                    </Label>
                    <Col sm={8}>
                        <Input state={state} name='content.date' />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={2}>
                        Trending hours
                    </Label>
                    <Col sm={8}>
                        <Input state={state} name='content.read' />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={2}>
                        Filter tags
                    </Label>
                    <Col sm={8}>
                        <Input state={state} name='content.filters' />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={2}>
                        Ad unit ID
                    </Label>
                    <Col sm={8}>
                        <Input state={state} name='content.auid' />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={2}>
                        Placement array
                    </Label>
                    <Col sm={8}>
                        <Input state={state} name='content.adPlacement' />
                    </Col>
                </FormGroup>
                <CodeHolder>
                    <div className='header'>Template</div>
                    <Editor
                        value={state.current.content.template || ''}
                        name='content.template'
                        tabSize={4}
                        onValueChange={code => state.updateAtPath('content.template', code)}
                        highlight={code => Prism.highlight(code, Prism.languages.html, 'html')}
                        padding={10}
                        style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 14,
                        }}
                    />
                </CodeHolder>
                <p>
                    Tags available for template:
                    <Highlight>{'{url}'}</Highlight>
                    <Highlight>{'{image}'}</Highlight>
                    <Highlight>{'{title}'}</Highlight>
                    <Highlight>{'{description}'}</Highlight>
                </p>

                <CodeHolder>
                    <div className='header'>CSS</div>
                    <Editor
                        value={state.current.content.css || ''}
                        name='content.css'
                        tabSize={4}
                        onValueChange={code => state.updateAtPath('content.css', code)}
                        highlight={code => Prism.highlight(code, Prism.languages.css, 'css')}
                        padding={10}
                        style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 14,
                        }}
                    />
                </CodeHolder>
            </Card>
        </>
    );
}
