import React from 'react';
import { useConfig } from 'config';
import { NavLink } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { Menu, Section } from './style';
import { useHistory } from 'react-router';

const MainMenu = props => {
    const history = useHistory();
    const config = useConfig();
    const menu = config.menu[history.location.pathname.split('/')[1]] || config.menu.containers;
    return (
        <Menu>
            {menu.map(section => {
                if (
                    section.permission &&
                    section.permission.some(r => props.userPermission.indexOf(r) === -1)
                )
                    return null;
                else
                    return (
                        <Section key={section.name}>
                            {section.items.map(item => {
                                if (
                                    section.permission &&
                                    section.permission.some(r => props.userPermission.indexOf(r) === -1)
                                )
                                    return null;
                                else
                                    return (
                                        <NavLink key={item.label} to={item.link} activeClassName='active'>
                                            <IonIcon icon={item.icon} />
                                            <span>{item.label}</span>
                                        </NavLink>
                                    );
                            })}
                        </Section>
                    );
            })}
        </Menu>
    );
};

export default MainMenu;
