import styled from 'styled-components'
import { colors } from 'style'

export const Holder = styled.div`
    position: relative;
    height: auto;
    width: 80%;
    max-width: 600px;
    z-index: 400;
    background: #fff;
    padding: 40px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,.3);
    box-shadow: 0 10px 20px rgba(50,50,50,.15);
    
    
    & > ion-icon {
        top:20px;
        right: 20px;
        position: absolute;
        font-size: 24px;
        cursor: pointer;
        color: ${colors.mediumGrey}
    }
`

export const Overlay = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 30;
    background: rgba(150,150,150,.5);
    perspective: 300px;
`