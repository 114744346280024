import styled from 'styled-components';
import { Holders, colors } from 'style';

export const SelectListHolder = styled.div`
    ${Holders.window}
    cursor: pointer;
    width: 100%;
    &.active {
        background-color: ${colors.primary};
        color: #fff;
        box-shadow: ${colors.primary}b3 0px 6px 13px -4px, inset rgb(255 255 255 / 32%) 0px 1px 0px 0px;
    }
`;
