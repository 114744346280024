/* 
    This creates an overlay that will slide in from the site
*/

import React, { useEffect, useState, useRef } from 'react';
import { Slide, Overlay } from './style';
import { useHistory } from 'react-router';
import { useKeyPress } from 'utils';
import { close } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

interface Props {
    children;
    size?: string;
    closeFunction?: () => void;
}

const SlideOverlay = ({ children, size = '', closeFunction }: Props) => {
    const history = useHistory();
    const [shouldRender, setRender] = useState(true);
    const [shouldShow, setShow] = useState(true);
    const wrapperRef = useRef(null);

    function handleClickOn(event) {
        if (wrapperRef.current && wrapperRef.current === event.target) {
            setShow(false);
        }
    }

    useKeyPress('Escape', () => {
        setShow(false);
    });

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOn);
        return () => {
            document.removeEventListener('mousedown', handleClickOn);
        };
    });

    useEffect(() => {
        if (shouldShow) setRender(true);
    }, [shouldShow]);

    const onAnimationEnd = () => {
        if (!shouldShow) {
            setRender(false);
            if (closeFunction) closeFunction();
            else history.goBack();
        }
    };

    return shouldRender ? (
        <>
            <Overlay
                ref={wrapperRef}
                style={{ animation: `${shouldShow ? 'fadeIn' : 'fadeOut'} .5s` }}
                onAnimationEnd={onAnimationEnd}
            />
            <Slide style={{ animation: `${shouldShow ? 'slideIn' : 'slideOut'} .5s` }} className={size}>
                <header>
                    <h1>Edit</h1>
                    <IonIcon
                        icon={close}
                        style={{ fontSize: '30px', cursor: 'pointer' }}
                        onClick={() => setShow(false)}
                    />
                </header>
                {children}
            </Slide>
        </>
    ) : (
        <></>
    );
};

export default SlideOverlay;
