import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import Edit from './edit';
import { notify } from 'react-notify-toast';
import { useParams } from 'react-router';

const PUBLISH = gql`
    mutation($ids: [ID], $env: JSON) {
        script(ids: $ids, env: $env) {
            ids
        }
    }
`;

const Deploy = () => {
    const { containerId } = useParams<{ containerId: string }>();
    const [env, setEnv] = useState({
        prod: false,
        dev: false,
    });

    const [publishCall, { loading: publishLoading }] = useMutation(PUBLISH, {
        onCompleted: () => {
            notify.show('Item Published', 'success', 1000);
        },
    });

    const publish = (id, environment) => {
        if (env.dev || env.prod) {
            publishCall({ variables: { ids: [id], env: environment } });
        } else {
            notify.show('You have not selected an environment to deploy to.', 'error', 3000);
        }
    };

    const toggleEnv = value => {
        setEnv({ ...env, [value]: !env[value] });
    };

    return (
        <Edit publish={publish} toggleEnv={toggleEnv} env={env} id={containerId} loading={publishLoading} />
    );
};

export default Deploy;
