import React from 'react';
import { Input, Card, RemoveButton } from 'components';
import { AdunitContent } from './style';

export const adunitList = (state, adunit, i, editIndex, triggerEdit) => (
    <Card key={`adunit-${i}`} onClick={() => triggerEdit(i)} active={i === editIndex ? 'active' : ''}>
        <AdunitContent>
            <Input name={`prebid.content[${i}].code`} placeholder='Adunit' />
            <RemoveButton onClick={() => state.splice('prebid.content', i)} />
        </AdunitContent>
    </Card>
);
