import React from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { Card, Input, CustomInput } from 'components';
import { State } from 'interfaces/state';

interface Props {
    state: State;
}

export default function Javascript({ state }: Props) {
    return (
        <>
            <Card>
                <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={2}>
                        Javascript Url
                    </Label>
                    <Col sm={8}>
                        <Input state={state} name='content.url' />
                    </Col>
                </FormGroup>
                <Row>
                    <Col>
                        <CustomInput
                            state={state}
                            type='switch'
                            id='onLoad'
                            name='content.onLoad'
                            label='Trigger event on Load'
                        />
                    </Col>
                </Row>
            </Card>
        </>
    );
}
