import { useState, useEffect } from 'react';

export const useKeyPress = (targetKey, action) => {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);

    // Add event listeners
    useEffect(() => {
        // If pressed key is our target key then set to true
        function downHandler({ key }) {
            if (key === targetKey) {
                setKeyPressed(true);
                action();
            }
        }

        // If released key is our target key then set to false
        const upHandler = ({ key }) => {
            if (key === targetKey) {
                setKeyPressed(false);
            }
        };

        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [action, targetKey]);

    return keyPressed;
};
