import sv from './purposes-sv.json';
import no from './purposes-no.json';
import bg from './purposes-bg.json';
import en from './purposes-en.json';
import ca from './purposes-ca.json';
import cs from './purposes-cs.json';
import da from './purposes-da.json';
import de from './purposes-de.json';
import el from './purposes-el.json';
import es from './purposes-es.json';
import et from './purposes-et.json';
import fi from './purposes-fi.json';
import fr from './purposes-fr.json';
import hr from './purposes-hr.json';
import hu from './purposes-hu.json';
import it from './purposes-it.json';
import ja from './purposes-ja.json';
import lt from './purposes-lt.json';
import lv from './purposes-lv.json';
import mt from './purposes-mt.json';
import nl from './purposes-nl.json';
import pl from './purposes-pl.json';
import pt from './purposes-pt.json';
import ro from './purposes-ro.json';
import ru from './purposes-ru.json';
import sk from './purposes-sk.json';
import sl from './purposes-sl.json';
import tr from './purposes-tr.json';
import zh from './purposes-zh.json';

// https://register.consensu.org/Translation
// https://vendorlist.consensu.org/v2/vendor-list.json

export const List = {
    sv: sv,
    en: en,
    no: no,
    bg: bg,
    ca: ca,
    cs: cs,
    da: da,
    de: de,
    el: el,
    es: es,
    et: et,
    fi: fi,
    fr: fr,
    hr: hr,
    hu: hu,
    it: it,
    ja: ja,
    lt: lt,
    lv: lv,
    mt: mt,
    nl: nl,
    pl: pl,
    pt: pt,
    ro: ro,
    ru: ru,
    sk: sk,
    sl: sl,
    tr: tr,
    zh: zh,
};

export const langugeList = {
    bg: 'Bulgarian',
    ca: 'Catalan',
    zh: 'Chinese',
    hr: 'Croatian',
    cs: 'Czech',
    da: 'Danish',
    nl: 'Dutch',
    en: 'English',
    et: 'Estonian',
    fi: 'Finnish',
    fr: 'French',
    de: 'German',
    el: 'Greek',
    hu: 'Hungarian',
    it: 'Italian',
    ja: 'Japanese',
    lt: 'Lithuanian',
    lv: 'Latvian',
    mt: 'Maltese',
    no: 'Norwegian',
    pl: 'Polish',
    pt: 'Portuguese',
    ro: 'Romanian',
    ru: 'Russian',
    sk: 'Slovak',
    sl: 'Slovenian',
    es: 'Spanish',
    sv: 'Swedish',
    tr: 'Turkish',
};
