import styled from 'styled-components';
import { Holders, colors } from 'style';

export const Criteria = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin-top: -1px;
    padding: 7px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    border-top: 1px solid rgba(0, 0, 0, 0);

    input,
    select {
        width: auto;
        margin-right: 10px;
    }

    select {
        background: ${colors.lightGrey};
    }

    &:hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    &:hover .test {
        display: block;
    }
    .test {
        display: none;
    }
`;

export const Group = styled.div`
    ${Holders.window};
    padding: 0px;
    position: relative;
    border-left: 3px solid #4e9ef5;
    margin: 10px 0px;

    .groupContent {
        padding: 10px 20px 30px;
    }
    .groupHeader {
        display: flex;
        padding: 20px;
        margin: 0px 0px 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        overflow: hidden;

        select {
            float: left;
            width: auto;
        }
        button {
            float: left;
            margin: 0px 0px 0px 10px;
            padding: 6px 20px;
            border: 1px solid #ced4da;
            background: none;
        }
        button:hover {
            background: #e6ecf1;
            color: #676a6c;
        }
    }

    .removeGroup {
        float: right;
        display: none;
    }

    .groupHeader:hover > .removeGroup {
        display: block;
    }
    select {
        background: ${colors.lightGrey};
    }
`;

export const InputHolder = styled.div`
    margin-top: 10px;
    input,
    select {
        padding: 10px 16px;
        border: 1px solid #ced4da;
        color: #495057;
        margin-right: 10px;
        border-radius: 4px;
    }
    input {
        padding: 8px 15px;
    }
    select {
        background: #e6ecf1;
    }
`;
