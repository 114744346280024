import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { notify } from 'react-notify-toast';
import EditSizes from './EditSizes';
import { Col, Row, Container } from 'reactstrap';
import { Button, List, Popup, Creator, Loader, Block } from 'components';
import { useStateHandler } from 'utils';
import { NetworkProfile } from 'interfaces/networkProfile';
import { GET_NETWORK_PROFILE, newItem, UPDATE_NETWORK_PROFILE } from 'requests/networkProfile';

interface SizeTemplate {
    type?: string | undefined;
    index?: number | null;
}
interface MutateVariables {
    profileData: NetworkProfile;
}

interface RequestVariable {
    getNetworkProfile: NetworkProfile;
}

function NetworkView() {
    const query = useQuery<RequestVariable>(GET_NETWORK_PROFILE);
    const state = useStateHandler<NetworkProfile>(query.data?.getNetworkProfile);
    const [save, mutation] = useMutation<RequestVariable, MutateVariables>(UPDATE_NETWORK_PROFILE, {
        onCompleted: () => {
            notify.show('Item saved', 'success', 1000);
        },
    });

    const [item, setItem] = useState<SizeTemplate>({
        type: undefined,
        index: null,
    });

    if (query.loading || !state.current || !state.current.profile) return <Loader />;
    if (query.error) return <p>Error :(</p>;

    if (!state.current.profile.sizeTemplates)
        return (
            <Container>
                <h1>Size Templates</h1>
                <p>You currently have not setup size templates for this network.</p>
                <Button
                    onClick={() => {
                        state.setAtPath('profile.sizeTemplates', []);
                    }}
                >
                    Cerate Size Templates
                </Button>
            </Container>
        );

    const sizeTemplates = state.current.profile.sizeTemplates;
    const RENDER_TAG = (rendeState, index) => ({
        sizeTemplates: (
            <EditSizes
                state={rendeState}
                index={index}
                item={sizeTemplates[index]}
                save={save}
                mutation={mutation}
            />
        ),
    });

    const toggleEdit = (type, index) => {
        setItem({
            type: type,
            index: index,
        });
    };

    const create = name => {
        state.push('profile.sizeTemplates', newItem(name));
    };

    return (
        <>
            <Popup show={item.type} callback={() => setItem({})}>
                {item.index != null && item.type && RENDER_TAG(state, item.index)[item.type]}
            </Popup>

            <Container>
                <Row>
                    <Col>
                        <Block title='Size Templates'>
                            <List
                                state='active'
                                onClick={(id, index) => {
                                    toggleEdit('sizeTemplates', index);
                                }}
                                onDelete={id => {
                                    const deleteIndex = state.current.profile.sizeTemplates.findIndex(
                                        el => el.id === id,
                                    );
                                    state.splice('profile.sizeTemplates', deleteIndex);
                                }}
                                deleteable={true}
                                header={true}
                                headerValues={[{ name: 'name', label: 'Name' }]}
                                data={state.current.profile.sizeTemplates || []}
                            />

                            <hr />

                            <Creator
                                type='template'
                                onSubmit={create}
                                containerId={undefined}
                                global={false}
                            />
                        </Block>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default NetworkView;
