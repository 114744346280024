import styled from 'styled-components';
import { colors, sidebar } from 'style';

export const Main = styled.div`
    background: ${colors.background};
    min-height: 100%;
    overflow: auto;
`;

export const MainHolder = styled.div`
    transition: all 0.3s ease-in-out;
    margin-left: ${props => (props.sidebar ? sidebar.menuHolder : 0)};
    padding: 0;
`;

export const Content = styled.div`
    padding: 140px 20px 100px;
    padding: ${props => (props.header ? '140px 20px 100px' : '20px')};
`;
