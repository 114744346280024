import React from 'react';
import { Container, Row, Col, Label } from 'reactstrap';
import { Block } from 'components';
import { useQuery, useMutation } from '@apollo/client';
import { GET_CONTAINER_PRIVACY_BY_ID, UPDATE_CONTAINER } from 'requests/containers';
import { notify } from 'react-notify-toast';
import { List } from './languages';
import { Card, Button, Form, CustomInput, SelectList, Input, Code, Loader } from 'components';
import { clean, useStateHandler } from 'utils';
import { useParams } from 'react-router';
import { ContainerType } from 'interfaces/containers';
import { Purpose } from 'interfaces/purposes';
import { Language } from 'interfaces/languages';

interface Request {
    getContainer: ContainerType;
    getPurposes: Purpose[];
    getLanguages: Language[];
}

interface QueryVariables {
    id: string;
}
interface Update {
    containerData: ContainerType;
}

const PrivacyHolder = () => {
    const { containerId } = useParams<{ containerId: string }>();
    const query = useQuery<Request, QueryVariables>(GET_CONTAINER_PRIVACY_BY_ID, {
        variables: { id: containerId },
    });
    const state = useStateHandler<ContainerType>(query.data?.getContainer);
    const [save, mutation] = useMutation<Request, Update>(UPDATE_CONTAINER, {
        onCompleted: () => {
            notify.show('Item saved', 'success', 1000);
        },
    });

    if (query.loading || !state.current.data) return <Loader />;
    if (query.error) return <p>Error :(</p>;

    const container = state.current;
    const languages = query.data?.getLanguages || [];

    const renderList = type => {
        return Object.keys(List['en'][type]).map(id => {
            const purpose = List['en'][type][id];

            return (
                <SelectList key={purpose.id} name={`cmp.tcf.${type}.p_${purpose.id}`} label={purpose.name} />
            );
        });
    };

    return (
        <Container>
            <h1>Privacy</h1>

            {languages.length !== 0 ? (
                <Form state={state}>
                    {container.cmp?.options?.enabled &&
                    !container.cmp.options.adnuntius &&
                    !container.cmp.options.tcf ? (
                        <Card type='warning'>
                            The CMP will not be triggered if there are no purposes enabled.
                        </Card>
                    ) : (
                        <></>
                    )}
                    <Card>
                        <Row>
                            <Col>
                                <CustomInput
                                    name='cmp.options.enabled'
                                    label='Enable CMP'
                                    type='switch'
                                    id='enableCMP'
                                />
                            </Col>
                        </Row>
                        {container.cmp?.options?.enabled ? (
                            <>
                                <hr />

                                <Row>
                                    <Col md='6'>
                                        <Label>Default Language</Label>
                                        <Input type='select' name='cmp.options.languageCode'>
                                            <option value=''>- Default Language -</option>
                                            {languages.map(language => (
                                                <option key={language.id} value={language.id}>
                                                    {language.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>

                                    <Col md='6'>
                                        <Label>Position</Label>
                                        <Input type='select' name='cmp.options.layout'>
                                            <option>- Select Layout -</option>
                                            <option value='overlay'>Overlay</option>
                                            <option value='messageBox'>Message Box</option>
                                            <option value='list'>List</option>
                                        </Input>
                                    </Col>

                                    <Col md='6'>
                                        <Label>Reload after days</Label>
                                        <Input type='number' name='cmp.options.reloadTime' />
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col>
                                        <CustomInput
                                            name='cmp.options.pageviewTrigger'
                                            label='Trigger Popup on page view'
                                            type='switch'
                                            id='triggerPageview'
                                        />

                                        <CustomInput
                                            name='cmp.options.editButton'
                                            label='Enable edit button'
                                            type='switch'
                                            id='editButton'
                                        />
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col>
                                        <CustomInput
                                            name='cmp.options.addCss'
                                            label='Add Css'
                                            type='switch'
                                            id='addCss'
                                        />

                                        <CustomInput
                                            name='cmp.options.removeDefaultCss'
                                            label='Remove default CSS'
                                            type='switch'
                                            id='removeCss'
                                        />
                                    </Col>
                                </Row>

                                {container.cmp?.options?.addCss ? (
                                    <Row>
                                        <Col>
                                            <Code name='cmp.options.css' type='css' />
                                        </Col>
                                    </Row>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </Card>

                    {container.cmp?.options?.enabled ? (
                        <>
                            <Card title='Purpose Options'>
                                <Row>
                                    <Col>
                                        <CustomInput
                                            name='cmp.options.adnuntius'
                                            label='Use Adnuntius Purposes'
                                            type='switch'
                                            id='enableAdnuntius'
                                        />

                                        <CustomInput
                                            name='cmp.options.tcf'
                                            label='Use TCF Purposes'
                                            type='switch'
                                            id='enableTCF'
                                        />
                                    </Col>
                                </Row>
                            </Card>
                            {container.cmp.options?.adnuntius ? (
                                <Row>
                                    <Col md='12'>
                                        <Block title='Purposes'>
                                            {query.data?.getPurposes.map(purpose => (
                                                <SelectList
                                                    key={purpose.id}
                                                    name={`cmp.adnuntius.purposes.${purpose.id}`}
                                                    label={purpose.name || ''}
                                                />
                                            ))}
                                        </Block>
                                    </Col>
                                </Row>
                            ) : (
                                <></>
                            )}

                            {container.cmp.options.tcf ? (
                                <Row>
                                    <Col md='12'>
                                        <Block title='Purposes'>{renderList('purposes')}</Block>
                                        <Block title='Special Features'>
                                            {renderList('specialFeatures')}
                                        </Block>
                                    </Col>
                                </Row>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </Form>
            ) : (
                <p>You need to create Languages in the network tag to enable this option.</p>
            )}
            <div className='footer'>
                <Button
                    loader={mutation.loading}
                    onClick={() => {
                        save({ variables: { containerData: clean(state.current) } });
                    }}
                >
                    Save
                </Button>
            </div>
        </Container>
    );
};

export default PrivacyHolder;
