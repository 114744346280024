import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, gql } from '@apollo/client';
import { env } from 'config';
import { notify } from 'react-notify-toast';

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(error => {
            console.log(`[GraphQL error]: ${error.message}`);

            notify.show(error.message, 'error', 7000);
            if (error.message.indexOf('jwt expired') > -1) {
                window.location.href = `/login?r=${window.location.href}`;
            }
        });
        throw graphQLErrors;
    }
    if (networkError) {
        notify.show(networkError.message, 'error', 7000);
        console.log(networkError);
    }
});

const data = () => {
    return {
        network: window.localStorage.getItem('network') || '',
        stateFilter: 'active',
        token: window.localStorage.getItem('token') || '',
        containerId: window.localStorage.getItem('containerId') || '',
        user: JSON.parse(window.localStorage.getItem('user') || '{}'),
    };
};

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    const network = localStorage.getItem('network');
    return {
        headers: {
            ...headers,
            network: network,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const link = ApolloLink.from([
    authLink,
    errorLink,
    new HttpLink({
        uri: `${env[env.environment].urls.api}/graphql`,
    }),
]);

const cache = new InMemoryCache();
const client = new ApolloClient({
    cache: cache,
    link: link,
});

const writeInitialData = async () => {
    return await cache.writeQuery({
        query: gql`
            query Cache {
                network
                stateFilter
                token
                containerId
                user
            }
        `,
        data: data(),
    });
};

writeInitialData();
client.onResetStore(writeInitialData);

export { client, cache };
