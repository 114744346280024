import React from 'react';
import { Form, Input, Loader, Button } from 'components';
import { useQuery, useMutation } from '@apollo/client';
import { Container, Label, Col, Row } from 'reactstrap';
import { langugeList } from '../../Containers/Privacy/languages';
import { Language } from 'interfaces/languages';
import { UPDATE_LANGUAGE, GET_LANGUAGE_BY_ID } from 'requests/languages';
import { notify } from 'react-notify-toast';
import { Purpose } from 'interfaces/purposes';
import { clean, useStateHandler } from 'utils';

interface Props {
    id: string;
}

export interface MutateVariables {
    languageData: Language;
}
export interface RequestVariable {
    getLanguage: Language;
    getPurposes: Purpose[];
}

export default function LanguageEdit({ id }: Props) {
    const query = useQuery<RequestVariable, Props>(GET_LANGUAGE_BY_ID, { variables: { id: id } });
    const state = useStateHandler<Language>(query.data?.getLanguage);
    const [save, mutation] = useMutation<RequestVariable, MutateVariables>(UPDATE_LANGUAGE, {
        onCompleted: () => {
            notify.show('Item saved', 'success', 1000);
        },
    });

    if (query.loading || !state.current) return <Loader />;
    if (query.error) return <p>Error :(</p>;
    const purposes = query.data?.getPurposes || [];

    return (
        <Container>
            <Form state={state}>
                <Row>
                    <Col md='6'>
                        <Row>
                            <Col>
                                <Label>Language Name</Label>
                                <Input name='name' />

                                <Label>Language</Label>
                                <Input type='select' name='languageCode'>
                                    <option value=''>- Language -</option>
                                    {Object.keys(langugeList).map(language => (
                                        <option key={language} value={language}>
                                            {langugeList[language]}
                                        </option>
                                    ))}
                                    <option value='other'>Other</option>
                                </Input>

                                <Label>Title</Label>
                                <Input name='texts.title' />

                                <Label>Message</Label>
                                <Input type='textarea' name='texts.message' />
                            </Col>
                        </Row>

                        <Row>
                            <Col md='6'>
                                <Label>Save</Label>
                                <Input name='texts.save' />
                            </Col>

                            <Col md='6'>
                                <Label>Back</Label>
                                <Input name='texts.back' />
                            </Col>
                        </Row>

                        <Row>
                            <Col md='6'>
                                <Label>Accept All</Label>
                                <Input name='texts.acceptAll' />
                            </Col>

                            <Col md='6'>
                                <Label>Reject All</Label>
                                <Input name='texts.rejectAll' />
                            </Col>
                        </Row>

                        <Row>
                            <Col md='6'>
                                <Label>Edit Button</Label>
                                <Input name='texts.editButton' />
                            </Col>
                            <Col md='6'>
                                <Label>Settings</Label>
                                <Input name='texts.settings' />
                            </Col>
                        </Row>

                        <Row>
                            <Col md='6'>
                                <Label>Accept</Label>
                                <Input name='texts.accept' />
                            </Col>
                        </Row>
                        {state.current.languageCode !== 'other' ? (
                            <>
                                <h2>TCF 2.0</h2>
                                <Row>
                                    <Col md='6'>
                                        <Label>Legitimate Interest</Label>
                                        <Input name='texts.legInt' />
                                    </Col>

                                    <Col md='6'>
                                        <Label>Details</Label>
                                        <Input name='texts.details' />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='6'>
                                        <Label>Partners</Label>
                                        <Input name='texts.partners' />
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <></>
                        )}
                    </Col>
                    <Col md='6'>
                        {purposes.map(purpose => (
                            <Row key={purpose.id}>
                                <Col>
                                    <h3>{purpose.name}</h3>

                                    <Label>Title</Label>
                                    <Input name={`texts.purposes.${purpose.id}.title`} />

                                    <Label>Text</Label>
                                    <Input type='textarea' name={`texts.purposes.${purpose.id}.text`} />

                                    <Label>Extra Information</Label>
                                    <Input type='textarea' name={`texts.purposes.${purpose.id}.info`} />
                                </Col>
                            </Row>
                        ))}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Button
                            loader={mutation.loading}
                            onClick={() => {
                                save({ variables: { languageData: clean(state.current) } });
                            }}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}
