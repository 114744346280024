// export const sources = {
//     'atm.browser': 'Browser',
//     'window.dataLayer': 'Datalayer',
//     'atm.data': 'ADN - Datalayer',
//     'atm.page.meta': 'Meta Data',
//     cookie: 'Cookie',
//     localStorage: 'Local storage',
// };

interface Source {
    name: string;
    code: string;
}

export const sources: Source[] = [
    { name: 'Browser', code: 'atm.browser' },
    { name: 'Custom', code: '' },
    { name: 'Datalayer', code: 'window.dataLayer' },
    { name: 'ADN - Datalayer', code: 'atm.data' },
    { name: 'Meta Data', code: 'atm.page.meta' },
    { name: 'Cookie', code: 'cookie' },
    { name: 'Local Storage', code: 'localStorage' },
];
