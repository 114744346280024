import React, { useEffect, useState } from 'react';
import { AuthContainer, AuthScreen, LogoHolder, Footer } from './style';
import { useConfig } from 'config';
import { Form, Input, Label } from 'reactstrap';
import Appinfo from 'containers/Appinfo';
import logo from 'assets/img/logo.png';
import { useHistory } from 'react-router';
import { cache } from 'apolloClient';
import { notify } from 'react-notify-toast';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { Button } from 'components';

const Login = () => {
    const [state, setState] = useState({
        username: '',
        password: '',
    });
    const history = useHistory();

    const MUTATION = gql`
        mutation($credentials: AuthInput) {
            authenticate(credentials: $credentials) {
                token
                user
            }
        }
    `;

    const [login] = useMutation(MUTATION);

    useEffect(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('network');
        localStorage.removeItem('user');
    }, []);

    const change = e => {
        const name = e.target.name;
        const value = e.target.value;
        const newState = { ...state, [name]: value };
        setState(newState);
    };

    const onSubmit = async (ev, values) => {
        ev.preventDefault();
        try {
            const { data } = await login({
                variables: {
                    credentials: values,
                },
            });
            console.log('DAT', data);
            await localStorage.setItem('token', data.authenticate.token);
            await localStorage.setItem('user', JSON.stringify(data.authenticate.user));
            await localStorage.setItem(
                'network',
                window.localStorage.getItem('network') || Object.keys(data.authenticate.user.networks)[0],
            );

            if (data.authenticate.token) {
                cache.writeQuery({
                    query: gql`
                        query Cache {
                            token
                            network
                            user
                        }
                    `,
                    data: {
                        token: data.authenticate.token,
                        network:
                            window.localStorage.getItem('network') ||
                            Object.keys(data.authenticate.user.networks)[0],
                        user: data.authenticate.user,
                    },
                });

                history.push('/containers');
            }
        } catch (e: any) {
            notify.show(e.message, 'error');
        }
    };

    const config = useConfig();

    return (
        <>
            <AuthScreen>
                <img src={logo} alt='logo' />
                <AuthContainer>
                    <LogoHolder>
                        <div>
                            <h1>{config.appName}</h1>
                            <p>Login</p>
                        </div>
                    </LogoHolder>
                    <div style={{ padding: '10px 20px 30px', overflow: 'hidden' }}>
                        <Form onSubmit={e => onSubmit(e, state)}>
                            <Label>Email</Label>
                            <Input defaultValue={state.username} name='username' onChange={change} />

                            <Label>Password</Label>
                            <Input
                                type='password'
                                defaultValue={state.password}
                                name='password'
                                onChange={change}
                            />

                            <Button>Login</Button>
                        </Form>
                    </div>
                </AuthContainer>
            </AuthScreen>

            <Footer>
                <Appinfo />
            </Footer>
        </>
    );
};

export default Login;
