import React from 'react';
import { Block } from 'components';
import { Col, Row, FormGroup, Label } from 'reactstrap';
import { RemoveButton, Card, AddButton, Input, CustomInput } from 'components';
import shortid from 'shortid';
import Select from 'react-select';
import { customTagStyles } from 'style';
import { Bidders, Bidder, BidderLeft, BidderRight, Size } from './style';
import bidders from './bidders';

export const renderDetails = (state, prebid, sites, adunit, adunitIndex, toggleTemplates, sizeTemplates) => {
    const adunitNameString = `prebid.content[${adunitIndex}]`;
    const sizeNameString = `${adunitNameString}.mediaTypes.banner.sizes`;
    const selectedSite = sites.find(site => site.id === prebid.requester.siteId);
    const options = selectedSite
        ? selectedSite.adunits.map(loadedUnit => {
              return {
                  value: loadedUnit.tagId,
                  label: loadedUnit.name,
              };
          })
        : [];

    return (
        <Block title={adunit.code || 'Adunit'} key={`adunit-${adunitIndex}`}>
            {prebid.requester.system === 'adnuntius' ? (
                <Card title='Bound Adunits'>
                    <Select
                        defaultValue={prebid.content[adunitIndex].map}
                        styles={customTagStyles}
                        isMulti
                        options={options}
                        onChange={vals => state.setAtPath(`prebid.content[${adunitIndex}].map`, vals)}
                    />
                </Card>
            ) : (
                <></>
            )}

            <Card
                title='Sizes'
                headerArea={
                    sizeTemplates ? (
                        <p style={{ cursor: 'pointer' }} onClick={() => toggleTemplates(sizeNameString)}>
                            Select Template
                        </p>
                    ) : (
                        ''
                    )
                }
            >
                {adunit.mediaTypes.banner.sizes.map((size, sizeIndex) => {
                    return (
                        <Size key={`size-${size.id}`}>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            name={`${sizeNameString}[${sizeIndex}].values[0]`}
                                            type='number'
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            name={`${sizeNameString}[${sizeIndex}].values[1]`}
                                            type='number'
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <RemoveButton onClick={() => state.splice(sizeNameString, sizeIndex)} />
                        </Size>
                    );
                })}

                <AddButton
                    size='small'
                    onClick={() => state.push(`${sizeNameString}`, { id: shortid.generate(), values: [] })}
                ></AddButton>
            </Card>

            <h2>Bidders</h2>
            <Bidders>
                {adunit.bids.map((bidder, bidIndex) => {
                    return (
                        <Bidder key={'bidder_' + bidder.id}>
                            <BidderLeft>
                                <FormGroup>
                                    <CustomInput
                                        defaultChecked={true}
                                        type='switch'
                                        id={`${bidIndex}-active`}
                                        name={`${adunitNameString}.bids[${bidIndex}].active`}
                                        label='Active'
                                    />
                                </FormGroup>
                                <Input
                                    type='select'
                                    name={`${adunitNameString}.bids[${bidIndex}].bidder`}
                                    hook={e => {
                                        const data = e.target.options[e.target.selectedIndex].dataset;
                                        const alias = data.name ? data.name : undefined;

                                        state.setAtPath(`${adunitNameString}.bids[${bidIndex}]`, {
                                            active: bidder.active,
                                            alias: alias,
                                            bidder: e.target.value,
                                            params: bidders[data.name || e.target.value].params,
                                        });
                                    }}
                                >
                                    <option value='' disabled>
                                        - Aliases -
                                    </option>
                                    <>
                                        {prebid.alias && (
                                            <>
                                                {prebid.alias.map(alias => (
                                                    <option
                                                        key={'bidder_' + alias.alias[0]}
                                                        data-name={alias.alias[1]}
                                                        value={alias.alias[0]}
                                                    >
                                                        {alias.alias[0]}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </>
                                    <option value='' disabled>
                                        - Bidder -
                                    </option>
                                    {Object.keys(bidders).map(bidderName => (
                                        <option key={'bidder_' + bidIndex + bidderName} value={bidderName}>
                                            {bidderName}
                                        </option>
                                    ))}
                                </Input>
                            </BidderLeft>

                            <BidderRight>
                                {Object.keys(bidder.params).map(bidderParam => {
                                    const inputType =
                                        bidders[bidder.alias || bidder.bidder].params[bidderParam];
                                    return (
                                        <FormGroup key={'bidder_' + bidderParam} row>
                                            <Label style={{ textAlign: 'right' }} sm={4}>
                                                {bidderParam}
                                            </Label>
                                            <Col sm={7}>
                                                <Input
                                                    name={`${adunitNameString}.bids[${bidIndex}].params.${bidderParam}`}
                                                    type={inputType}
                                                />
                                            </Col>
                                        </FormGroup>
                                    );
                                })}
                            </BidderRight>

                            <RemoveButton
                                onClick={() => state.splice(`${adunitNameString}.bids`, bidIndex)}
                            />
                        </Bidder>
                    );
                })}
            </Bidders>
            <AddButton
                size='small'
                onClick={() =>
                    state.push(`${adunitNameString}.bids`, {
                        id: shortid.generate(),
                        active: true,
                        bidder: '',
                        params: {},
                    })
                }
            />
        </Block>
    );
};
