import styled from 'styled-components';
import { colors } from 'style';

export const Section = styled.div`
    border-bottom: 1px solid #676a6c69;
    padding: 15px 0px;
`;
export const Menu = styled.div`
    width: 100%;
    margin-top: 30px;

    a,
    a:visited {
        color: ${colors.accentLight};
        display: flex;
        padding: 15px 30px;
        align-items: center;
        font-weight: 500;
    }

    a:hover {
        text-decoration: none;
    }
    a.active,
    a:visited.active,
    a.active ion-icon {
        color: ${colors.primary};
    }
    a.active,
    a:visited.active {
        border-left: 3px solid ${colors.primary};
    }

    ion-icon {
        color: ${colors.accentMedium};
        font-size: 24px;
        margin-right: 15px;
    }

    hr {
        background: #333942;
    }
`;
