import styled from 'styled-components';
import { colors, shadows, sidebar } from 'style';

export const SideBarHolder = styled.div`
    width: ${sidebar.menuHolder};
    height: 100%;
    top: 0;
    position: fixed;
    z-index: 10;
`;

export const SideMenuHolder = styled.div`
    width: ${sidebar.sideMenu};
    height: 100%;
    top: 0;
    background: ${colors.accentVeryDark};
    display: flex;
`;

export const SideMenu = styled.div`
    display: flex;
    flex-direction: column;

    a:visited,
    a:hover {
        color: inherit;
        text-decoration: none;
    }

    a {
        width: 70px;
        height: 80px;
        margin: 10px 10px;
        border-radius: 10px;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        color: ${colors.accentLight};

        &.active,
        &.active ion-icon,
        &.active span {
            color: ${colors.primary};
        }

        &.active {
            box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px -5px;
            background: ${colors.accentDark};
            border: 1px solid rgba(123, 123, 123, 0.1);
        }

        ion-icon {
            font-size: 24px;
            color: ${colors.accentLight};
            margin-bottom: 10px;
        }
    }

    span {
        color: ${colors.accentLight};
        font-size: 10px;
    }
`;

export const Menu = styled.div`
    overflow: auto;
    transition: all 0.3s;
    position: fixed;
    width: ${sidebar.menu};
    height: 100%;
    top: 0;
    z-index: 10;
    left: ${sidebar.sideMenu};
    display: block;
    background: ${colors.accentDark};
    border-left: 1px solid rgba(123, 123, 123, 0.1);
    ${shadows.shallow};

    img {
        width: 80%;
        margin: 20px 10%;
    }

    span {
        opacity: 1;
    }

    &.open {
        width: ${sidebar.menu};
    }
`;

export const Version = styled.div`
    color: ${colors.darkGrey};
    font-size: 12px;
    margin: 15px 25px;
    opacity: 0.9;
    position: fixed;
    bottom: 0px;
`;
