import React from 'react';
import { Container } from 'reactstrap';
import { useParams } from 'react-router';
import Edit from './Edit';

import { SlideOverlay } from 'components';
import { Purpose } from 'interfaces/purposes';
import { useList } from 'utils/useList';
import { GET_PURPOSES, UPDATE_PURPOSE, newItem } from 'requests/purposes';

interface Request {
    getPurposes: Purpose[];
}
interface Mutation {
    purposeData: Purpose;
}

function PurposesView() {
    const { id } = useParams<{ id: string }>();

    return (
        <Container>
            <h1>Purpose</h1>
            {useList<Request, Mutation, Purpose>({
                parent: 'network',
                type: 'purpose',
                request: GET_PURPOSES,
                mutation: UPDATE_PURPOSE,
                createItem: newItem,
                headers: [{ name: 'name', label: 'Name' }],
                sortable: true,
            })}

            {id && (
                <SlideOverlay>
                    <Edit id={id} />
                </SlideOverlay>
            )}
        </Container>
    );
}

export default PurposesView;
