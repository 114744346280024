import { gql } from '@apollo/client';
import { Tag } from 'interfaces/tags';

export const GET_TAG_BY_ID = gql`
    query getTagById($id: ID, $state: State, $containerId: String) {
        stateFilter @client @export(as: "state")
        network @client
        getTag(id: $id) {
            id
            name
            state
            type
            content
            containers {
                id
                name
            }
            triggers(filter: { state: active, containerId: $containerId }) {
                id
                name
            }
            purposes {
                id
                name
            }
        }
        getTags(filter: { state: $state, containerId: $containerId }) {
            id
            name
        }

        getPurposes(filter: { state: $state }) {
            id
            name
        }

        getTriggers(filter: { state: $state, containerId: $containerId }) {
            id
            name
        }
    }
`;

export const GET_TAG_LIST = gql`
    query getTags($state: State, $containerId: String) {
        stateFilter @client @export(as: "state")
        network @client
        getTags(filter: { state: $state, containerId: $containerId }) {
            id
            name
            type
            global
        }
    }
`;

export const GET_GLOBAL_TAGS = gql`
    query getTags($state: State) {
        stateFilter @client @export(as: "state")
        network @client
        getTags(filter: { state: $state, global: true }) {
            id
            name
            type
            global
        }
    }
`;

export const GET_TAGS_BY_CONTAINER = gql`
    query getTagsByContainer($state: State, $containerId: String) {
        stateFilter @client @export(as: "state")
        network @client
        getTags(filter: { state: $state, containerId: $containerId }) {
            id
            name
            state
            type
            global
        }
    }
`;

export const UPDATE_TAGS = gql`
    mutation($tagData: [TagInput]) {
        editTags(tagData: $tagData) {
            id
            name
            state
            type
            content
            global
        }
    }
`;

export const newItem = (name: string, containerArray: string[], global: boolean): Tag => {
    const tag = {
        name: name,
        state: 'active',
        type: 'empty',
        add: {
            containers: containerArray,
        },
    };

    if (global) {
        tag['global'] = true;
    }

    return tag;
};
