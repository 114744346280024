import React from 'react';
import { Form, Input, Button, Loader, Card } from 'components';
import { Container, Label } from 'reactstrap';
import { sources } from './sources';

import { UPDATE_VARIABLE, GET_VARIABLE_BY_ID } from 'requests/variables';
import { Variable } from 'interfaces/variables';
import { useQuery, useMutation } from '@apollo/client';
import { notify } from 'react-notify-toast';
import { clean, useStateHandler } from 'utils';

interface Props {
    id: string;
}

export interface MutateVariables {
    variableData: Variable;
}
export interface RequestVariable {
    getVariable: Variable;
}

export default function VariableEdit({ id }: Props) {
    // const request = Variable.useRequest({ id: id });
    const query = useQuery<RequestVariable, Props>(GET_VARIABLE_BY_ID, { variables: { id: id } });
    const state = useStateHandler<Variable>(query.data?.getVariable);
    const [save, mutation] = useMutation<RequestVariable, MutateVariables>(UPDATE_VARIABLE, {
        onCompleted: () => {
            notify.show('Item saved', 'success', 1000);
        },
    });

    if (query.loading || !state.current) return <Loader />;
    if (query.error) return <p>Error :(</p>;

    return (
        <Container>
            {state.current.global === true && (
                <Card type='warning'>
                    This is a global variable. Changes on this variable will change all linked variables as
                    well.
                </Card>
            )}
            <Label>Variable Name</Label>
            <Form state={state}>
                <label>Variable Name</label>
                <Input name='name' />
                <label>Source</label>
                <Input type='select' name='type'>
                    <option>- Select Source -</option>
                    {sources.map(source => (
                        <option key={source.code} value={source.code}>
                            {source.name}
                        </option>
                    ))}
                </Input>
                <Label>Variable</Label>
                <Input name='variable' />

                <Label>Default value</Label>
                <Input name='default' />

                <div className='footer'>
                    <Button
                        loader={mutation.loading}
                        onClick={() => {
                            save({ variables: { variableData: clean(state.current) } });
                        }}
                    >
                        Save
                    </Button>
                </div>
            </Form>
        </Container>
    );
}
