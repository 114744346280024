import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { Card, Input } from 'components';
import { State } from 'interfaces/state';

interface Props {
    state: State;
}

export default function Kilkaya({ state }: Props) {
    return (
        <>
            <Card>
                <FormGroup row>
                    <Label style={{ textAlign: 'right' }} sm={2}>
                        Kilkaya
                    </Label>
                    <Col sm={8}>
                        <Input state={state} name='content.kilkayaId' />
                    </Col>
                </FormGroup>
            </Card>
        </>
    );
}
