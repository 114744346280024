import React from 'react';

import { NavLink } from 'react-router-dom';
import { SubNav } from './style';
import { useLocation } from 'react-router';
import { useConfig } from 'config';

const SubMenu = () => {
    const config = useConfig();
    const location = useLocation();
    const topNav = location.pathname.split('/')[1];
    const menu = config.menu[topNav] && config.menu[topNav].children ? config.menu[topNav].children : [];

    return (
        <SubNav>
            {menu.map(item => {
                return (
                    <NavLink key={item.link} to={item.link} activeClassName='active'>
                        {item.label}
                    </NavLink>
                );
            })}
        </SubNav>
    );
};

export default SubMenu;
