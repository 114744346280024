import { useState, useEffect } from 'react';
import _ from 'lodash';
import { State } from 'interfaces/state';

export function useStateHandler<T>(init?: T): State {
    const [state, setState] = useState<T>({ ...init } as T);
    // const [callback, setCallback] = useState<any>({ fn: false });

    useEffect(() => {
        setState({ ...init } as T);
        return () => {
            setState({} as T);
        };
    }, [init]);

    // useEffect(() => {
    //     if (callback.fn) {
    //         callback.fn();
    //     }
    //     return () => {
    //         setCallback({ fn: false });
    //     };
    // }, [callback]);

    const actions = {
        current: state,

        update: (data: T) => {
            const merged = _.merge({}, { ...state }, { ...data });
            setState({ ...merged });
            return merged;
        },

        updateAtPath: (path: string, data: T) => {
            const current = _.cloneDeep(actions.current);
            const newState = _.set(current, path, data);
            actions.update(newState);
        },

        push: (path: string, item) => {
            const array = [..._.get(actions.current, path)];
            array.push(item);
            const newState = _.set({}, path, array);
            actions.update(newState);
        },

        concat: (path: string, data) => {
            const current = _.cloneDeep(actions.current);
            const oldArray = _.get(current, path);
            const array = oldArray.concat(data);
            const newState = _.set(current, path, array);
            actions.set(newState);
        },

        splice: (path: string, index: number) => {
            const current = _.cloneDeep(actions.current);
            const array = _.get(current, path);
            array.splice(index, 1);
            const newState = _.set(current, path, array);
            actions.set(newState);
        },

        delete: (path: string) => {
            const current = _.cloneDeep(actions.current);
            _.unset(current, path);
            actions.set(current);
        },

        set: (data: any) => {
            setState({ ...data });
        },

        setAtPath: (path: string, data: any) => {
            const current = _.cloneDeep(actions.current);
            const newState = _.set(current, path, data);
            actions.set(newState);
        },

        setMultipleAtPath: (arr: { [key: string]: any }[]) => {
            let current = _.cloneDeep(actions.current);
            for (const item of arr) {
                const key = Object.keys(item)[0];
                current = _.set(current, key, item[key]);
            }
            actions.set(current);
        },

        change: (e, type, hook) => {
            const name = e.target.name;
            let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
            if (type === 'number') value = Number(value);
            const newState = _.set({}, name, value);
            actions.update(newState);
            if (hook) hook(e);
        },

        getValueFrom: (name: string) => {
            const curr = actions.current;
            return _.get(curr, name, '');
        },
    };

    return { ...actions };
}
