import React, { useState } from 'react';
import { Input, Form } from 'reactstrap';
import { useKeyPress } from 'utils';
import { CreatorHolder, AddHolder } from './style';
import { addCircle } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { checkWriteRole } from 'utils/checkWriteRole';

interface CreatorProps {
    onSubmit: (name: string, containerId?: string, global?: boolean) => void;
    type: string;
    containerId?: string;
    global?: boolean;
    itemList?: any;
}

const Creator = ({ onSubmit, type, containerId, global, itemList }: CreatorProps) => {
    const [open, toggle] = useState(false);
    const [name, setName] = useState('');
    const [error, setError] = useState('');

    useKeyPress('Escape', () => {
        toggle(false);
        setError('');
        setName('');
    });

    const submit = e => {
        e.preventDefault();
        let err = false;
        if (itemList) {
            console.log(itemList);
            itemList.forEach(element => {
                if (element.name === name) {
                    setError('Name already exists');
                    err = true;
                }
            });
        }
        if (err === false) {
            toggle(false);
            onSubmit(name, containerId, global);
            setName('');
        }
    };

    const change = ({ target }) => {
        setError('');
        setName(target.value);
    };

    const renderable = checkWriteRole();

    if (renderable)
        return (
            <CreatorHolder>
                {open ? (
                    <>
                        <div style={{ overflow: 'auto' }}>
                            <Form onSubmit={submit}>
                                <Input defaultValue={name} onChange={change} autoFocus={true} />
                                <Input type='submit' value='Create' disabled={name === ''} />
                            </Form>
                        </div>
                        {error && <div style={{ color: '#ec7474', margin: '3px 0px 4px' }}>{error}</div>}
                        <p onClick={() => toggle(false)}>Cancel</p>
                    </>
                ) : (
                    <AddHolder onClick={() => toggle(true)}>
                        <IonIcon icon={addCircle} color='#fff' style={{ marginRight: '5px' }} />
                        Add {type}
                    </AddHolder>
                )}
            </CreatorHolder>
        );
    else return <></>;
};

export default Creator;
