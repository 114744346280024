import { gql } from '@apollo/client';

export const GET_UI_INFO = gql`
    query getUiInfo {
        network @client
        user @client
        getContainers(filter: { state: active }) {
            id
            name
            state
        }
    }
`;
