import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { Button, ToggleButton, Loader, Block, Card } from 'components';
import { ContainerType } from 'interfaces/containers';
import { useStateHandler } from 'utils';
// import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { GET_CONTAINERS } from 'requests/containers';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';

interface Props {
    id: string;
}

interface Request {
    getContainers: ContainerType;
}

const Edit = props => {
    const query = useQuery<Request, Props>(GET_CONTAINERS);
    const state = useStateHandler<ContainerType>(query.data?.getContainers);

    if (query.loading || !state.current.data) return <Loader />;
    if (query.error) return <p>Error :(</p>;

    //     const includeStub =
    //         state.current.cmp?.options?.tcf && state.current.cmp?.options?.enabled
    //             ? `<!-- IMPORTANT! this must be insert at the top of the <head> tag -->
    // <script src="https://t.atmng.io/consent/stub.js" ></script>

    // <!-- This can be inserted at the top of the body tag -->
    // `
    //             : '';

    // const codeDev = `${includeStub}<script src="https://t.atmng.io/${
    //     state.current.data.networkOverride || localStorage.getItem('network')
    // }/${containerId}.dev.js" async></script>`;
    // const codeProd = `${includeStub}<script src="https://t.atmng.io/${
    //     state.current.data.networkOverride || localStorage.getItem('network')
    // }/${containerId}.prod.js" async></script>`;

    return (
        <>
            <Container>
                <Block title='Deploy'>
                    <Row>
                        <Col>
                            <Card style={{ overflow: 'hidden' }} title='Deploy to'>
                                <ToggleButton enabled={props.env.dev} onClick={() => props.toggleEnv('dev')}>
                                    Development
                                </ToggleButton>
                                <ToggleButton
                                    enabled={props.env.prod}
                                    onClick={() => props.toggleEnv('prod')}
                                >
                                    Production
                                </ToggleButton>
                            </Card>
                        </Col>
                    </Row>

                    {/* {props.env.dev && (
                        <SyntaxHighlight language='html' title='Development Tag' code={codeDev} />
                    )}
                    {props.env.prod && (
                        <SyntaxHighlight language='html' title='Production Tag' code={codeProd} />
                    )} */}

                    <Button
                        disabled={!props.env.dev && !props.env.prod}
                        loader={props.loading.publish}
                        onClick={() => {
                            props.publish(props.id, props.env);
                        }}
                    >
                        Publish
                    </Button>
                </Block>
            </Container>
        </>
    );
};

export default Edit;
