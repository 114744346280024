import styled from 'styled-components';
import { colors, shadows } from 'style';

const ButtonStyle = `
    transition: all 0.3s;
    padding: 20px 30px;
    margin: 10px 5px 20px;
    background: ${colors.primary};
    color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 30px;
    border-radius: 4px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2), inset 0px 1px 0px rgba(255, 255, 255, 0.4);
    float: right;
    display: flex;
    align-items: center;
`;

export const ButtonHolder = styled.button`
    ${ButtonStyle}

    &:disabled {
        background: ${colors.lightPrimary};
    }

    ion-icon {
        font-size: 20px;
        margin: 0;
        line-height: 0;
        display: block;
        float: left;
    }

    &.grey {
        background: ${colors.lightGrey};
        color: ${colors.darkGrey};
    }

    &.left {
        float: left;
        margin-right: 10px;
    }
`;

export const ButtonAdd = styled.div`
    width: 100%;
    &.small {
        padding: 5px;
    }
`;

export const IconHolder = styled.button`
    display: block;
    cursor: pointer;
    background: none;
    transition: all 0.2s;
    width: 180px;
    padding: 20px;
    margin: 20px auto;
    border-radius: 10px;
    border: 1px dashed ${colors.mediumGrey};

    span {
        width: 100%;
        display: block;
        text-transform: uppercase;
        font-size: 10px;
        color: ${colors.mediumGrey};
        text-align: center;
    }

    ion-icon {
        display: block;
        transition: all 0.2s;
        color: ${colors.mediumGrey};
        font-size: 28px;
        margin: 0px auto;
    }

    &:hover {
        transition: all 0.2s;
        background: ${colors.primary};
        border: 1px solid ${colors.lightGrey};
        ${shadows.deep};
        color: #fff;

        ion-icon,
        span {
            color: #fff;
        }
    }

    &.small {
        padding: 10px;
        margin: 5px auto;
        width: 60px;
        ion-icon {
            font-size: 16px;
        }
    }
`;

export const FilterRow = styled.div`
    float: left;
    width: 100%;
`;

const ButtonRadius = '4px';

export const FilterGroupHolder = styled.div`
    display: block;
    margin: 15px 10px 15px 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: ${ButtonRadius};
    float: left;
    ${shadows.insetShallow};

    button {
        padding: 5px 15px;
        border-radius: none;
        background: none;
        border: none;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 14px;
        color: ${colors.darkGrey};
    }

    button:first-child {
        border-left: none;
    }

    button.active {
        margin: -1px;
        background: ${colors.primary};
        color: #fff;
        box-shadow: 0px 5px 10px #5692ff82, inset 0px 1px 0px rgba(255, 255, 255, 0.25);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: ${ButtonRadius};
    }

    button.active + button {
        border: none;
    }

    &.large button {
        padding: 10px 25px;
    }
`;

export const ToggleButtonHolder = styled.div`
    cursor: pointer;
    padding: 10px 25px;
    border: 2px solid ${colors.lightGrey};
    color: ${colors.mediumGrey};
    border-radius: 3px;
    transition: all 0.4s;
    margin-left: auto;
    float: left;
    margin: 15px 5px 20px;
    font-weight: bold;

    &.enabled {
        border: 2px solid ${colors.primary};
        color: ${colors.primary};
    }
`;

export const RemoveButtonHolder = styled.div`
    cursor: pointer;
    padding: 7px 8px 1px;
    border: 1px solid ${colors.lightGrey};
    color: ${colors.mediumGrey};
    border-radius: 3px;
    transition: all 0.4s;
    margin-left: auto;
    order: 2;
    display: inline-table;

    &:hover {
        color: ${colors.primary};
        border: 1px solid ${colors.primary};
        box-shadow: ${colors.primary}b3 0px 3px 10px -4px;
    }
`;

export const TagHolder = styled.div`
    cursor: pointer;
    ${ButtonStyle}
    padding: 0;
    float: left;
    overflow: hidden;
    span {
        padding: 5px 15px;
    }
    div {
        display: flex;
        transition: all 0.2s;
        padding: 9px;
        color: ${colors.lightPrimary};
    }

    div:hover {
        background-color: ${colors.lightPrimary};
        color: #fff;
    }
`;
