import { gql } from '@apollo/client';
import { ContainerType } from 'interfaces/containers';
import shortid from 'shortid';

export const GET_CONTAINER_PROFILE_BY_ID = gql`
    query getContainerProfileById($id: ID) {
        getContainer(id: $id) {
            id
            name
            tagType
            updatedAt
            updatedBy
            data
            prebid
            cmp
        }
        getNetworkProfile {
            profile
        }
        getSites {
            id
            name
            adunits {
                id
                name
                tagId
            }
        }
    }
`;

export const GET_CONTAINER_BY_ID = gql`
    query getContainerById($id: ID) {
        getContainer(id: $id) {
            id
            name
            tagType
            updatedAt
            updatedBy
            data
            prebid
            cmp
        }
    }
`;

export const GET_CONTAINER_PRIVACY_BY_ID = gql`
    query getContainerPrivacyById($id: ID, $state: State) {
        stateFilter @client @export(as: "state")
        getContainer(id: $id) {
            id
            name
            tagType
            updatedAt
            updatedBy
            data
            prebid
            cmp
            tags {
                id
                name
            }
        }
        getPurposes(filter: { state: $state }) {
            id
            name
            state
            externalId
            forced
        }
        getLanguages(filter: { state: $state }) {
            id
            name
            state
            languageCode
        }
    }
`;

export const UPDATE_CONTAINER = gql`
    mutation($containerData: [ContainerInput]) {
        editContainers(containerData: $containerData) {
            id
            name
            tagType
            state
            data
            prebid
            cmp
        }
    }
`;

export const GET_CONTAINERS = gql`
    query getContainers($state: State) {
        stateFilter @client @export(as: "state")
        getContainers(filter: { state: $state }) {
            id
            name
            state
        }
    }
`;

export const newItem = (name): ContainerType => {
    return {
        name: name,
        state: 'active',
        tagType: 'legacy',
        data: {
            cssAdd: true,
            cssOverride: true,
            gdpr: false,
            gdprChoiceButton: true,
            geo: false,
            position: 'overlay',
        },
        prebid: {
            analytics: [],
            alias: [],
            requester: {
                system: 'adnuntius',
            },
            content: [
                {
                    id: shortid.generate(),
                    code: '',
                    bids: [],
                    mediaTypes: {
                        banner: { sizes: [] },
                    },
                },
            ],
        },
    };
};
