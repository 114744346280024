import React, { useState } from 'react';
import { DropDownHolder, NetworkSelector } from './style';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useHistory } from 'react-router';
// import { gql } from '@apollo/client';

const Networks = props => {
    const [dropDown, toggleDropdown] = useState(false);
    const history = useHistory();

    const networks = props.data.user.networks;
    const onClick = network => {
        window.localStorage.setItem('network', network);
        // props.client.writeQuery({
        //     query: gql`
        //         query Cache {
        //             network
        //         }
        //     `,
        //     data: {
        //         network: network,
        //     },
        // });
        props.client.resetStore();
        history.push('/containers');
    };

    const currentNetwork = window.localStorage.getItem('network') || '';

    return (
        <DropDownHolder>
            <Dropdown isOpen={dropDown} toggle={() => toggleDropdown(!dropDown)}>
                <DropdownToggle tag='div' data-toggle='dropdown' aria-expanded={dropDown}>
                    <NetworkSelector>{networks[currentNetwork].networkName}</NetworkSelector>
                </DropdownToggle>
                <DropdownMenu right>
                    <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
                        <DropdownItem header>Networks</DropdownItem>

                        {Object.keys(networks)
                            .sort()
                            .map(networkId => {
                                return (
                                    <DropdownItem
                                        key={'network_' + networkId}
                                        className={networkId === currentNetwork ? 'active' : ''}
                                        onClick={() => {
                                            onClick(networkId);
                                        }}
                                    >
                                        {networks[networkId].networkName}
                                    </DropdownItem>
                                );
                            })}
                    </div>
                </DropdownMenu>
            </Dropdown>
        </DropDownHolder>
    );
};

export default Networks;
