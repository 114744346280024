import React from 'react';
import { DeletableRow } from 'style';
import { Card, RemoveButton, Input, Button, CustomInput, FilterGroup, FilterRow } from 'components';
import bidders from './bidders';
import { Col, Row, Label } from 'reactstrap';
import shortid from 'shortid';
import { Targeting } from './targeting';
import { CodeHolder } from 'style';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';

export const renderSystems = (state, prebid, sites) => {
    return (
        <>
            <Row>
                <Col>
                    <CustomInput type='switch' id='gdpr' name='prebid.config.gdpr' label='Use GDPR Module' />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CustomInput
                        type='switch'
                        id='loadPrebidJs'
                        name='prebid.loadPrebidJs'
                        label='Load Prebid.js'
                    />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <CodeHolder>
                        <div className='header'>Custom Settings</div>
                        <Editor
                            value={state.current.prebid?.config?.custom || ''}
                            name='prebid.config.custom'
                            tabSize={4}
                            onValueChange={code => state.updateAtPath('prebid.config.custom', code)}
                            highlight={code =>
                                Prism.highlight(code, Prism.languages.javascript, 'javascript')
                            }
                            padding={10}
                            style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 14,
                            }}
                        />
                    </CodeHolder>
                </Col>
            </Row>
            <hr />

            {/* ----- Targeting ----- */}

            <Row>
                <Col md='12'>
                    <Targeting state={state} />
                </Col>
            </Row>

            <hr />

            {/* ----- Requesting adserver ----- */}
            <Row>
                <Col md={7}>
                    <Row>
                        <Col>
                            <h2>Requesting system</h2>
                            <FilterRow>
                                <FilterGroup className='large'>
                                    <button
                                        className={prebid.requester.system === 'adnuntius' ? 'active' : ''}
                                        onClick={() => {
                                            state.updateAtPath('prebid.requester.system', 'adnuntius');
                                        }}
                                    >
                                        Adnuntius
                                    </button>
                                    <button
                                        className={prebid.requester.system === 'gam' ? 'active' : ''}
                                        onClick={() => {
                                            state.updateAtPath('prebid.requester.system', 'gam');
                                        }}
                                    >
                                        Google Ad Manager
                                    </button>
                                    <button
                                        className={prebid.requester.system === 'none' ? 'active' : ''}
                                        onClick={() => {
                                            state.updateAtPath('prebid.requester.system', 'none');
                                        }}
                                    >
                                        None
                                    </button>
                                </FilterGroup>
                            </FilterRow>
                        </Col>
                    </Row>

                    {prebid.requester.system === 'adnuntius' ? (
                        <Row>
                            <Col>
                                <Card>
                                    <Label>Select Site</Label>
                                    <Input type='select' name='prebid.requester.siteId'>
                                        <option>- Select Site -</option>
                                        {sites.map(site => (
                                            <option key={site.id} value={site.id}>
                                                {site.name}
                                            </option>
                                        ))}
                                    </Input>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        <></>
                    )}
                    {prebid.requester.system === 'gam' ? (
                        <Row>
                            <Col>
                                <Card>
                                    <Label>Network ID</Label>
                                    <Input type='text' name='prebid.requester.networkId' />
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        <></>
                    )}
                </Col>

                {/* ----- Prebid Aliases ----- */}

                <Col md={5}>
                    {prebid.alias && prebid.alias.length > 0 ? (
                        <Card>
                            <div>
                                <Row>
                                    <Col md={5}>
                                        <Label>Alias name</Label>
                                    </Col>
                                    <Col md={5}>
                                        <Label>Alias</Label>
                                    </Col>
                                </Row>

                                {prebid.alias.map((alias, i) => (
                                    <DeletableRow key={alias.id}>
                                        <Col md={5}>
                                            <Input name={`prebid.alias[${i}].alias[0]`} />
                                        </Col>
                                        <Col md={5}>
                                            <Input name={`prebid.alias[${i}].alias[1]`} type='select'>
                                                <option value=''>- Bidder -</option>
                                                {Object.keys(bidders).map(bidderName => (
                                                    <option key={'bidder_' + bidderName} value={bidderName}>
                                                        {bidderName}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                        <Col md={2}>
                                            <RemoveButton onClick={() => state.splice('prebid.alias', i)} />
                                        </Col>
                                    </DeletableRow>
                                ))}
                            </div>
                        </Card>
                    ) : (
                        <h2>No Alias for bidders</h2>
                    )}
                    <Row>
                        <Col md={12}>
                            <Button
                                onClick={() =>
                                    state.push('prebid.alias', { id: shortid.generate(), alias: [] })
                                }
                            >
                                Create Alias
                            </Button>
                        </Col>
                    </Row>
                    <div></div>
                </Col>
            </Row>
        </>
    );
};
