import styled from 'styled-components';
import { shadows, colors } from 'style';

export const Slide = styled.div`
    position: fixed;
    overflow-y: auto;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    z-index: 400;
    background: #fff;
    padding: 40px;
    ${shadows.veryDeep}
    transform: translate3d(0,0,0);

    header {
        display: flex;

        .md-close-icon {
            cursor: pointer;
        }
    }

    &.large {
        width: calc(100% - 370px);
        background: ${colors.background};
    }

    .footer {
        border-top: 1px solid rgba(123, 123, 123, 0.2);
        background: ${colors.windowBackground};
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
    }
`;

export const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 30;
    background: rgba(50, 50, 50, 0.5);
    transform: translate3d(0, 0, 0);
`;
