import React from 'react';
import { Main, MainHolder, Content } from './style';
import Header from './Header';
import SideBar from './SideBar';
import { useQuery } from '@apollo/client';
import Loader from 'components/Loader';
import { ContainerType } from 'interfaces/containers';
import { User } from 'interfaces/uiInterfaces';
import { useParams } from 'react-router';
import { GET_UI_INFO } from 'requests/ui';

interface Request {
    getContainers: ContainerType;
    getUser: User;
    User: User;
}

const AppMain = ({ items, children }) => {
    const { containerId } = useParams<{ containerId: string }>();
    const { data, loading, error, client } = useQuery<Request>(GET_UI_INFO, {
        onCompleted: () => {},
    });

    const contains = (array, value) => {
        if (array) return array.indexOf(value) > -1 ? true : false;
        else return false;
    };

    const noSidebar = contains(items, 'no-sidebar');
    const noHeader = contains(items, 'no-header');

    if (error) return null;
    if (loading) return <Loader />;
    return (
        <Main>
            <SideBar data={data} noSidebar={noSidebar} />
            {!noHeader && <Header data={data} client={client} containerId={containerId} />}
            <MainHolder sidebar={!noSidebar}>
                <Content header={!noHeader}>{children}</Content>
            </MainHolder>
        </Main>
    );
};

export default AppMain;
