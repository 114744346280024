import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Card, Form, Input, Button, RemoveButton, Loader } from 'components';
import { Trigger } from 'interfaces/triggers';
import { Container, Label } from 'reactstrap';
import { Group, Criteria, InputHolder } from './style';
import { clean, useStateHandler } from 'utils';
import { sources } from 'views/Containers/Variables/sources';
import { useParams } from 'react-router';
import { GET_TRIGGER_BY_ID, UPDATE_TRIGGER, newGroup, newCriteria } from 'requests/triggers';
import { notify } from 'react-notify-toast';
import { Variable } from 'interfaces/variables';
import { Tag } from 'interfaces/tags';

interface Props {
    id: string;
    containerId?: string;
}

interface UpdateTrigger {
    triggerData: Trigger;
}
interface RequestTrigger {
    getTrigger: Trigger;
    getVariables: Variable[];
    getTags: Tag[];
}

export default function Edit({ id }: Props) {
    const { containerId } = useParams<{ containerId: string }>();
    // const request = Trigger.useRequest({ id: id });
    const query = useQuery<RequestTrigger, Props>(GET_TRIGGER_BY_ID, { variables: { id: id, containerId } });
    const state = useStateHandler<Trigger>(query.data?.getTrigger);
    const [save, mutation] = useMutation<RequestTrigger, UpdateTrigger>(UPDATE_TRIGGER, {
        onCompleted: () => {
            notify.show('Item saved', 'success', 1000);
        },
    });

    const [criteriaError, setCriteriaError] = useState<boolean | any>(false);

    let first = true;
    if (query.loading || !state.current.criteria) return <Loader />;
    if (query.error) return <p>Error :(</p>;

    // Function to rewrite old naming to dot notation.
    if (state.current.trigger.indexOf(':') > -1) {
        const triggerReWrite = state.current.trigger.split(':').join('.');
        state.setAtPath('trigger', triggerReWrite);
    }

    if (state.current.trigger === 'browser.state.pageview-' + containerId) {
        const triggerReWrite = 'browser.state.pageview';
        state.setAtPath('trigger', triggerReWrite);
    }

    if (state.current.trigger === 'browser.state.pageloaded') {
        const triggerReWrite = 'browser.state.pageload';
        state.setAtPath('trigger', triggerReWrite);
    }

    const variables = query.data?.getVariables || [];
    const tags = query.data?.getTags || [];
    const scripts = tags.filter(tag => {
        if (tag.type === 'javascript' && tag.content?.onLoad === true) return tag;
        else return null;
    });

    console.log(scripts);
    const variableBySource = variables.reduce((acc, curr) => {
        if (curr.type) {
            acc[curr.type] = acc[curr.type] || [];
            acc[curr.type].push(curr);
        }
        return { ...acc };
    }, {});

    const availableSources = [...new Set(variables.map(variable => variable.type))] as string[];

    const render = {
        group: (groupItem, parentName, index) => {
            const itemName = `${parentName}[${index}].values`;
            return (
                <Group key={groupItem.id}>
                    <div className='groupHeader'>
                        <Input type='select' name={`${parentName}[${index}].operand`}>
                            <option value='and'>And</option>
                            <option value='or'>Or</option>
                        </Input>
                        {first ? (
                            <>{(first = false)}</>
                        ) : (
                            <RemoveButton
                                className='removeGroup'
                                onClick={() => {
                                    state.splice(parentName, index);
                                }}
                            />
                        )}

                        <Button type='grey' onClick={() => state.push(itemName, newGroup())}>
                            Add Group
                        </Button>
                        <Button type='grey' onClick={() => state.push(itemName, newCriteria())}>
                            Add Condition
                        </Button>
                    </div>

                    <div className='groupContent'>{criterias(groupItem.values, itemName)}</div>
                </Group>
            );
        },

        criteria: (criteriaItem, parentName, index) => {
            const itemName = `${parentName}[${index}]`;
            return (
                <Criteria key={criteriaItem.id} className={criteriaError[criteriaItem.id] ? 'error' : ''}>
                    <Input type='select' name={`${itemName}.source`}>
                        <option>- Source -</option>
                        {availableSources.map(source => {
                            return (
                                <option key={source} value={`${source}`}>
                                    {sources[source]}
                                </option>
                            );
                        })}
                    </Input>

                    {criteriaItem.source !== 'element' ? (
                        <Input type='select' name={`${itemName}.attribute`}>
                            <option>- Criteria -</option>
                            {variableBySource[criteriaItem.source] ? (
                                variableBySource[criteriaItem.source].map(variable => {
                                    return (
                                        <option key={variable.id} value={`${variable.variable}`}>
                                            {variable.name}
                                        </option>
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </Input>
                    ) : (
                        <Input type='text' name={`${itemName}.attribute`} />
                    )}

                    <Input type='select' name={`${itemName}.operand`}>
                        <option>- select -</option>
                        <option value='contains'>Contains</option>
                        <option value='equals'>Equals</option>
                        <option value='gt'>Greater Than</option>
                        <option value='lt'>Less Than</option>
                    </Input>

                    <Input type='text' name={`${itemName}.value`} />

                    <RemoveButton className='test' onClick={() => state.splice(parentName, index)} />
                </Criteria>
            );
        },
    };

    const criterias = (criteriaValues, parentName) => {
        return criteriaValues.map((criteria, index) => {
            if (criteria.isGroup) {
                return render.group(criteria, parentName, index);
            } else {
                return render.criteria(criteria, parentName, index);
            }
        });
    };

    const checkCriteria = (criteriaValues, errors = {}) => {
        for (const criteria of criteriaValues) {
            if (criteria.isGroup) checkCriteria(criteria.values, errors);
            else {
                if (
                    criteria.value === '' ||
                    criteria.source === '' ||
                    criteria.operand === '' ||
                    criteria.attribute === ''
                ) {
                    errors[criteria.id] = true;
                }
            }
        }
        if (Object.keys(errors).length > 0) {
            setCriteriaError(errors);
            throw new Error('Some Criterias are malformed');
        }
    };

    const triggerTypes = [
        { type: 'browser.state.pageview', name: 'Pageview' },
        { type: 'browser.state.pageload', name: 'PageLoad' },
    ];

    const triggerTypesMapped = triggerTypes.map(trigger => trigger.type);

    const changer = e => {
        const triggerString = state.current.trigger.split('.');
        triggerString[Number(e.target.name)] = e.target.value;
        const trigger = triggerString.join('.');
        state.setAtPath('trigger', trigger);
    };

    const customTrigger = () => {
        const currentValue = state.current.trigger.split('.');
        return (
            <InputHolder>
                <select name='0' onChange={changer} defaultValue={currentValue[0]}>
                    <option value=''>- Select trigger Type -</option>
                    <option value='tags'>Tags</option>
                    <option value='script'>Script</option>
                </select>
                {currentValue[0] === 'tags' ? (
                    <select name='1' onChange={changer} defaultValue={currentValue[1]}>
                        <option value=''>- Select Tag -</option>
                        {tags.map(tag => (
                            <option key={`tag-${tag.id}`} value={tag.id}>
                                {tag.name}
                            </option>
                        ))}
                    </select>
                ) : (
                    <></>
                )}
                {currentValue[0] === 'script' ? (
                    <select name='1' onChange={changer} defaultValue={currentValue[1]}>
                        <option value=''>- Select Script -</option>
                        {scripts.map(script => (
                            <option key={`script-${script.id}`} value={script.id}>
                                {script.name}
                            </option>
                        ))}
                    </select>
                ) : (
                    <></>
                )}
            </InputHolder>
        );
    };

    const triggerType = type => {
        let custom = false;

        if (!triggerTypesMapped.includes(type)) {
            triggerTypes.push({ type: type, name: 'Custom' });
            custom = true;
        } else {
            triggerTypes.push({ type: 'custom', name: 'Custom' });
        }

        return (
            <div>
                <Input type='select' name='trigger'>
                    <option value=''>- Select trigger Type -</option>
                    {triggerTypes.map(trigger => (
                        <option key={trigger.name} value={trigger.type}>
                            {trigger.name}
                        </option>
                    ))}
                </Input>
                {custom ? customTrigger() : <></>}
            </div>
        );
    };

    return (
        <>
            <Container>
                {state.current.global === true && (
                    <Card type='warning'>
                        This is a global variable. Changes on this variable will change all linked variables
                        as well.
                    </Card>
                )}
                <Form state={state}>
                    <Card>
                        <Label>Trigger Name</Label>
                        <Input name='name' />
                    </Card>
                    <Card>
                        <Label>Trigger Type</Label>
                        {triggerType(state.current.trigger)}
                    </Card>

                    {criterias(state.current.criteria, 'criteria')}
                </Form>
            </Container>
            <div className='footer'>
                <Button
                    loader={mutation.loading}
                    onClick={() => {
                        checkCriteria(state.current.criteria);
                        save({ variables: { triggerData: clean(state.current) } });
                    }}
                >
                    Save
                </Button>
            </div>
        </>
    );
}
