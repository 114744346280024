export const checkWriteRole = () => {
    let renderable = true;
    const user = localStorage.getItem('user') || '';
    const userJson = user ? JSON.parse(user) : null;
    const network = localStorage.getItem('network') || null;
    const containerLocalId = localStorage.getItem('containerId') || null;

    if (
        network &&
        containerLocalId &&
        !userJson?.networks[network].networkRole.includes('admin') &&
        userJson?.networks[network]?.containerRoles[containerLocalId]
    ) {
        if (!userJson?.networks[network]?.containerRoles[containerLocalId].includes('write'))
            renderable = false;
    }

    return renderable;
};
