import React from 'react';
import {
    ButtonHolder,
    ButtonAdd,
    IconHolder,
    FilterGroupHolder,
    ToggleButtonHolder,
    RemoveButtonHolder,
    TagHolder,
} from './style';
import { Spinner } from 'reactstrap';
import { add, refreshCircleOutline, trashOutline, close } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { checkWriteRole } from 'utils/checkWriteRole';

export const AddButton = props => (
    <ButtonAdd className={props.size}>
        <IconHolder {...props} className={props.size}>
            <IonIcon icon={add} />
            {!props.size && <span>Add{props.title ? ' ' + props.title : ''}</span>}
        </IconHolder>
    </ButtonAdd>
);

export const Button = props => {
    const renderable = checkWriteRole();
    if (renderable)
        return (
            <ButtonHolder
                disabled={props.loader}
                {...props}
                className={`${props.size} ${props.type} ${props.left ? 'left' : 'right'}`}
            >
                {props.icon && <IonIcon icon={refreshCircleOutline} />}
                {props.loader && <Spinner color='white' size={'sm'} style={{ marginRight: '10px' }} />}
                {props.children}
            </ButtonHolder>
        );
    else return <></>;
};

export const FilterGroup = props => <FilterGroupHolder {...props}>{props.children}</FilterGroupHolder>;

export const ToggleButton = props => (
    <ToggleButtonHolder {...props} className={props.enabled ? 'enabled' : ''}>
        {props.children}
    </ToggleButtonHolder>
);

export const RemoveButton = props => (
    <RemoveButtonHolder onClick={props.onClick}>
        <IonIcon icon={trashOutline} />
    </RemoveButtonHolder>
);

export const Tag = props => (
    <TagHolder>
        <span onClick={props.onClick}>{props.children}</span>
        <div onClick={props.remove}>
            <IonIcon icon={close} />
        </div>
    </TagHolder>
);
