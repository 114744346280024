import React from 'react';
import { HeaderHolder } from './style';
import Network from './Network';
import User from './User';
import SubMenu from './SubMenu';
import Containers from './Containers';

const Header = ({ data, client, containerId }) => {
    return (
        <>
            <HeaderHolder>
                <SubMenu />
                <User user={data.user} client={client} />
                <Network data={data} client={client} />
                <Containers data={data} containerId={containerId} />
            </HeaderHolder>
        </>
    );
};

export default Header;
