import styled from 'styled-components';
import { colors } from 'style';

export const AppinfoHolder = styled.div`
    width: 100%;
    text-align: center;
    margin: 15px;
    color: ${colors.darkGrey};

    a {
        color: ${colors.darkGrey};
    }

    span {
        margin: 0px 30px;
    }
`;
