import { Spinner } from 'reactstrap';
import React from 'react';

const Loader = props => {
    return (
        <div style={props.style || { width: '100%', display: 'block', textAlign: 'center', padding: '20px' }}>
            <Spinner color='primary' />
        </div>
    );
};

export default Loader;
