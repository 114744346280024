import styled from 'styled-components';
import { colors, sidebar } from 'style';

export const HeaderHolder = styled.div`
    width: calc(100% - ${sidebar.menuHolder});
    margin-left: ${sidebar.menuHolder};
`;

export const DropDownHolder = styled.div`
    display: inline-block;
    float: right;
    margin: 10px;
    height: 40px;
    font-weight: 400;
`;

export const NetworkSelector = styled.div`
    padding: 7px;
    display: block;
    cursor: pointer;
    border-bottom: 1px solid ${colors.lightGrey};
`;

export const UserHolder = styled.div`
    position: fixed;
    margin: 10px;
    height: 40px;
    right: 10px;
    top: 10px;
`;

export const UserItem = styled.div`
    color: #fff;
    font-weight: bold;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background: ${colors.primary};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

export const SubNav = styled.div`
    float: left;
    a {
        display: inline-block;
        padding: 15px 30px;
        align-items: center;
        font-weight: 500;
    }

    a:hover {
        text-decoration: none;
    }
    a.active,
    a:visited.active {
        color: ${colors.primary};
        border-bottom: 3px solid ${colors.primary};
    }
`;
