import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import Edit from './edit';
import { notify } from 'react-notify-toast';
import { useParams } from 'react-router';
import { useStateHandler } from 'utils';
import { useQuery } from '@apollo/client';
import { GET_CONTAINER_BY_ID } from 'requests/containers';
import { ContainerType } from 'interfaces/containers';
import { Loader } from 'components';

interface Props {
    id: string;
}

interface Request {
    getContainer: ContainerType;
}

const PUBLISH = gql`
    mutation($ids: [ID], $env: JSON) {
        script(ids: $ids, env: $env) {
            ids
        }
    }
`;

const Deploy = () => {
    const { containerId } = useParams<{ containerId: string }>();
    const [env, setEnv] = useState({
        prod: false,
        dev: false,
    });

    const query = useQuery<Request, Props>(GET_CONTAINER_BY_ID, {
        variables: { id: containerId },
    });
    const state = useStateHandler<ContainerType>(query.data?.getContainer);

    const [publishCall, { loading: publishLoading }] = useMutation(PUBLISH, {
        onCompleted: () => {
            notify.show('Item Published', 'success', 1000);
        },
    });

    const publish = (id, environment) => {
        if (env.dev || env.prod) {
            publishCall({ variables: { ids: [id], env: environment } });
        } else {
            notify.show('You have not selected an environment to deploy to.', 'error', 3000);
        }
    };

    const toggleEnv = value => {
        setEnv({ ...env, [value]: !env[value] });
    };

    if (query.loading || !state.current.data) return <Loader />;
    if (query.error) return <p>Error :(</p>;

    const cmpEnabled = state.current.cmp?.options?.tcf || state.current.cmp?.options?.enabled;
    const includeStub = cmpEnabled
        ? `<!-- IMPORTANT! this must be insert at the top of the <head> tag -->
<script src="https://t.atmng.io/consent/stub.js" ></script>

<!-- This can be inserted at the top of the body tag -->
`
        : '';

    const tagType = query.data?.getContainer.tagType;
    const networkName = state.current.data.networkOverride || localStorage.getItem('network');
    const network = tagType && tagType !== 'ac' ? '/' + networkName + '/' : '/';

    const URL = `${includeStub}<script src="https://t.atmng.io${network}`;
    const devFile = tagType !== 'ac' ? `${containerId}.dev.js` : `ac.js?id=${containerId}&env=dev`;
    const prodFile = tagType !== 'ac' ? `${containerId}.prod.js` : `ac.js?id=${containerId}`;
    const codeDev = `${URL}${devFile}" async></script>`;
    const codeProd = `${URL}${prodFile}" async></script>`;

    const code = {
        codeDev,
        codeProd,
    };

    return (
        <Edit
            publish={publish}
            toggleEnv={toggleEnv}
            env={env}
            id={containerId}
            loading={publishLoading}
            code={code}
        />
    );
};

export default Deploy;
