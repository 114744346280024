import React from 'react';
import { Col, Row } from 'reactstrap';
import { CodeHolder } from 'style';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import { State } from 'interfaces/state';

interface Props {
    state?: State;
}

export default function JavascriptTag({ state }: Props) {
    const highlight = code => {
        return Prism.highlight(code, Prism.languages.javascript, 'js');
    };
    return (
        <Row>
            <Col>
                <CodeHolder>
                    <div className='header'>Script</div>
                    <Editor
                        value={state?.current.content.script || ''}
                        name='content.script'
                        tabSize={4}
                        onValueChange={code => state?.updateAtPath('content.script', code)}
                        highlight={highlight}
                        padding={10}
                        style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 14,
                        }}
                    />
                </CodeHolder>
            </Col>
        </Row>
    );
}
