const variables = {
    '123': 'hello',
    '456': 'yepp',
};

function removeHelper() {
    var oldContainer = document.getElementById('variable_container');
    if (oldContainer) oldContainer.remove();
}

function createBlock(text, target) {
    removeHelper();
    var container = document.createElement('div');
    container.setAttribute('id', 'variable_container');
    target.parentNode.insertBefore(container, target.nextSibling);
    for (const [value] of Object.entries(variables)) {
        var item = document.createElement('div');
        item.addEventListener('click', function () {
            var currentValue = text.value;
            var caret = text.selectionStart;
            var before = currentValue.slice(0, caret);
            var after = currentValue.slice(caret, currentValue.length);
            text.value = before + value + '}}' + after;
            const e = new Event('change');
            text.dispatchEvent(e);
            removeHelper();
        });
        item.innerHTML = '<p>' + value + '</p>';
        item.style.cursor = 'pointer';
        container.appendChild(item);
    }
}

export default (e, target?) => {
    target = target || e;
    var text = e.value;
    var caret = e.selectionStart;
    var caretMT = text.length >= 2 ? text.slice(caret - 2, caret) : undefined;
    if (caretMT === '{{') {
        createBlock(e, target);
    } else {
        removeHelper();
    }
};
