import shortid from 'shortid';

const tagDefaults = {
    empty: {
        name: '- Select Tag Type -',
        content: {},
    },

    javascriptCustom: {
        name: 'Custom Javascript',
        content: {
            script: '',
        },
    },
    javascript: {
        name: 'Javascript',
        content: {
            url: '',
        },
    },

    html: {
        name: 'HTML Tag',
        content: {
            script: '',
            target: '',
        },
    },

    pixel: {
        name: 'Pixel',
        content: {
            url: '',
        },
    },

    zummyRecs: {
        name: 'Zummy',
        content: {
            css: '',
            date: '',
            filter: '',
            max: '',
            network: '',
            read: '',
            template: '',
        },
    },

    pubmaticSync: {
        name: 'Pubmatic User Sync',
        content: {
            providerId: '',
            folderId: '',
        },
    },

    google: {
        name: 'Google',
        content: {
            tags: [
                {
                    id: shortid.generate(),
                    code: '',
                },
            ],
        },
    },
    adnuntius: {
        name: 'Adnuntius Ad Request',
        content: {
            globals: [],
            adunits: [],
        },
    },
    googleTagManager: {
        name: 'Google Tag Manager',
        content: {
            containerId: '',
        },
    },
    googleAnalytics: {
        name: 'Google Analytics',
        content: {
            tagId: '',
            disablePageView: false,
        },
    },
    adnuntiusData: {
        name: 'Adnuntius Data Tag',
        content: {
            folderId: '',
        },
    },
    kilkaya: {
        name: 'Kilkaya Tag',
        content: {
            kilkayaId: '',
        },
    },
};

export default tagDefaults;
