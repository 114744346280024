import styled from 'styled-components';
import { Holders } from 'style';

export const Bidder = styled.div`
    ${Holders.window};
    display: flex;
    align-items: center;
    position: relative;
`;

export const Bidders = styled.div`
    width: 100%;

    .form-group {
        margin-bottom: 5px;
    }

    label {
        margin: 5px 0 0;
    }
`;

export const BidderLeft = styled.div`
    width: 30%;
    float: left;
    padding: 0 20px 0 0;
`;

export const BidderRight = styled.div`
    width: 70%;
    float: left;
    padding: 0 0 0 20px;
`;

export const AdunitContent = styled.div`
    display: flex;

    width: 100%;

    input {
        width: calc(100& - 50px);
    }
`;

export const Size = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    .form-group {
        padding-right: 20px;
    }
`;
