import styled from 'styled-components';

const Block = styled.div`
    background: rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    border: 1px solid #dfe0e2;
    box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.1);
    margin: 0px 0 20px;
    overflow: hidden;

    ion-icon {
        cursor: pointer;
    }
`;

const Title = styled.div`
    overflow: hidden;
    width: 100%;
    padding: 20px;
    font-weight: bold;
    border-bottom: 1px solid #eef0f3;

    input {
        float: right;
        width: auto;
        margin: -7px 0;
        background: rgba(186, 186, 186, 0.1);
    }
`;

const Content = styled.div`
    width: 100%;
    padding: 10px 20px 20px;
`;
export { Content, Block, Title };
