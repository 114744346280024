import React, { useState } from 'react';
import { Form, Input, Loader, Button, CustomInput } from 'components';
import { useQuery, useMutation } from '@apollo/client';
import { Container, Label, Col, Row } from 'reactstrap';
import { GET_PREBID_BIDDER_BY_ID } from 'requests/prebidBidders';
import { GET_BIDDER_BY_ID, UPDATE_BIDDER } from 'requests/bidder';
import { notify } from 'react-notify-toast';
import { clean, useStateHandler } from 'utils';
import { Bidder } from 'interfaces/bidder';
import { PrebidBidder } from 'interfaces/prebidBidders';

interface Props {
    id: string;
}

export interface MutateBidder {
    bidderData: Bidder;
}
export interface RequestBidder {
    getBidder: Bidder;
    getPrebidBidders: PrebidBidder[];
}
export interface RequestPrebidBidder {
    getPrebidBidder: PrebidBidder;
}

export default function LanguageEdit({ id }: Props) {
    const [prebidBidderCode, setPrebidBidderCode] = useState<string | undefined>('');
    const query = useQuery<RequestBidder, Props>(GET_BIDDER_BY_ID, {
        variables: { id: id },
        onCompleted: data => {
            setPrebidBidderCode(data.getBidder.bidderCode);
        },
    });
    const queryPrebidBidder = useQuery<RequestPrebidBidder, Props>(GET_PREBID_BIDDER_BY_ID, {
        variables: { id: prebidBidderCode || '' },
    });
    const state = useStateHandler<Bidder>(query.data?.getBidder);
    const [save, mutation] = useMutation<RequestBidder, MutateBidder>(UPDATE_BIDDER, {
        onCompleted: () => {
            notify.show('Item saved', 'success', 1000);
        },
    });

    if (query.loading || queryPrebidBidder.loading || !state.current) return <Loader />;
    if (query.error) return <p>Error :(</p>;
    const prebidBidders = query.data?.getPrebidBidders;
    const currentBidder = queryPrebidBidder.data?.getPrebidBidder;
    return (
        <Container>
            <Form
                state={state}
                onSubmit={e => {
                    e.preventDefault();
                    console.log(state.current);
                    save({ variables: { bidderData: clean(state.current) } });
                }}
            >
                <Row>
                    <Col md='12'>
                        <CustomInput type='switch' id='active' name='active' label='Active' />
                        <Label>Name</Label>
                        <Input name='name' />

                        <Label>Alias</Label>
                        <Input name='alias' />

                        <Label>Prebid Bidder</Label>
                        <Input
                            type='select'
                            name='bidderCode'
                            onChange={evt => {
                                console.log(evt.target.value);
                                const bidderCode = evt.target.value;
                                state.setAtPath('bidderCode', bidderCode);
                                setPrebidBidderCode(bidderCode);
                            }}
                        >
                            <option value=''>- BidderCode -</option>
                            {prebidBidders &&
                                prebidBidders.map(bidder => (
                                    <option key={bidder.bidderCode} value={bidder.bidderCode}>
                                        {bidder.name}
                                    </option>
                                ))}
                            <option value='other'>Other</option>
                        </Input>
                    </Col>
                </Row>
                <hr />

                <Row>
                    <Col>
                        {currentBidder &&
                            currentBidder.params.map(param => {
                                const label = `${param.name} - ${param.type}`;
                                const type = param.type === 'integer' ? 'number' : 'text';
                                const key = `${currentBidder.name}-${param.name}`;
                                return (
                                    <div key={key}>
                                        <Label>{label}</Label>
                                        <Input type={type} name={`params.${param.name}`} />
                                    </div>
                                );
                            })}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button loader={mutation.loading}>Save</Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}
