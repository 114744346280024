import React from 'react';
import { Input, SimpleCard, Block, AddButton, RemoveButton } from 'components';
import shortid from 'shortid';
import { Row, Col, Label } from 'reactstrap';
import { State } from 'interfaces/state';

interface Props {
    state?: State;
}

export default function GoogleTag({ state }: Props) {
    return (
        <Block title={'Google Adunits'}>
            {state?.current.content.tags.map((tag, i) => (
                <SimpleCard key={tag.id}>
                    <Row>
                        <Col>
                            <Label>Google Ad unit</Label>
                            <Input state={state} name={`content.tags[${i}].code`} />
                        </Col>
                        <Col>
                            <div style={{ float: 'left', width: 'calc( 100% - 50px)' }}>
                                <Label>Target Div Id</Label>
                                <Input state={state} name={`content.tags[${i}].divId`} />
                            </div>
                            <div style={{ float: 'right', marginTop: '16px' }}>
                                <RemoveButton onClick={() => state.splice(`content.tags`, i)} />
                            </div>
                        </Col>
                    </Row>
                </SimpleCard>
            ))}

            <AddButton
                onClick={() => {
                    state?.push('content.tags', {
                        id: shortid.generate(),
                        code: '',
                    });
                }}
            />
        </Block>
    );
}
