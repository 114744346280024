import React from 'react';
import { Loader } from 'components';
import { State } from 'interfaces/state';

import Adnuntius from './Adnuntius';
import AdnuntiusData from './AdnuntiusData';
import JavascriptCustom from './JavascriptCustom';
import Javascript from './Javascript';
import Html from './Html';
import Pixel from './Pixel';
import Google from './Google';
import ZummyTag from './Zummy-recs';
import Kilkaya from './Kilkaya';
import GoogleTagManager from './GoogleTagManager';
import GoogleAnalytics from './GoogleAnalytics';

interface Props {
    state?: State;
}

export default function TagView({ state }: Props) {
    const RENDER_TAG = currentState => ({
        adnuntius: <Adnuntius state={currentState} />,
        adnuntiusData: <AdnuntiusData state={currentState} />,
        javascript: <Javascript state={currentState} />,
        javascriptCustom: <JavascriptCustom state={currentState} />,
        html: <Html state={currentState} />,
        google: <Google state={currentState} />,
        googleAnalytics: <GoogleAnalytics state={currentState} />,
        googleTagManager: <GoogleTagManager state={currentState} />,
        pixel: <Pixel state={currentState} />,
        zummyRecs: <ZummyTag state={currentState} />,
        kilkaya: <Kilkaya state={currentState} />,
    });

    if (!state) return <Loader />;
    return state.current ? <>{RENDER_TAG(state)[state.current.type]}</> : <Loader />;
}
