import { gql } from '@apollo/client';
import { Purpose } from 'interfaces/purposes';

export const GET_PURPOSE_BY_ID = gql`
    query getPurpose($id: ID) {
        stateFilter @client @export(as: "state")
        getPurpose(id: $id) {
            id
            name
            state
            externalId
            forced
            adnuntiusPurpose
            index
        }
    }
`;

export const GET_PURPOSES = gql`
    query getPurposes($state: State) {
        stateFilter @client @export(as: "state")
        getPurposes(filter: { state: $state }) {
            id
            name
            state
            externalId
            forced
            index
        }
    }
`;

export const UPDATE_PURPOSE = gql`
    mutation updatePurposes($purposeData: [PurposeInput]) {
        editPurposes(purposeData: $purposeData) {
            id
            name
            state
            externalId
            forced
            adnuntiusPurpose
        }
    }
`;

export const newItem = (name, containerArray): Purpose => {
    return {
        name: name,
        state: 'active',
        externalId: '',
        forced: false,
        index: 0,
        adnuntiusPurpose: null,
        add: {
            containers: containerArray,
        },
    };
};
