import React from 'react';
import {
    ListHolder,
    ListItem,
    ListHeader,
    ListHeaderItem,
    ItemSection,
    EmptyList,
    EditSection,
    DragHandle,
} from './style';
import { useParams } from 'react-router';
import { IonIcon } from '@ionic/react';
import { refreshCircleOutline, trashOutline, menuOutline, globeOutline } from 'ionicons/icons';
import { SortableContainer, SortableHandle, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { checkWriteRole } from 'utils/checkWriteRole';

interface ParamTypes {
    id: string;
}

const DragHandler = SortableHandle(() => (
    <DragHandle>
        <IonIcon icon={menuOutline} />
    </DragHandle>
));

const SetListItem = SortableElement(({ item, index, parentprops, id, clickIndex }) => {
    const global = item.global && !parentprops.global ? true : false;
    const listValues = parentprops.headerValues || [];
    const renderable = checkWriteRole();
    const onDelete = (e, itemId) => {
        e.stopPropagation();
        if (parentprops.onDelete) parentprops.onDelete(itemId);
    };

    const onRestore = (e, itemId) => {
        e.stopPropagation();
        if (parentprops.onRestore) parentprops.onRestore(itemId);
    };

    const click = (itemId, clickId) => {
        if (parentprops.state === 'active') {
            if (parentprops.onClick) parentprops.onClick(itemId, clickId);
        }
    };

    return (
        <ListItem
            onClick={() => click(item.id, clickIndex)}
            className={`${item.id === id ? 'active ' : ''} ${global ? 'global' : ''}`}
        >
            {global && (
                <IonIcon
                    icon={globeOutline}
                    style={{ fontSize: '20px', marginRight: '10px', opacity: 0.6 }}
                />
            )}
            {listValues.map(value => (
                <ItemSection className={parentprops.sortable ? 'sortable' : ''} key={'s' + item[value.name]}>
                    {item[value.name]}
                </ItemSection>
            ))}
            {parentprops.deleteable && renderable && !global && (
                <EditSection>
                    {parentprops.state !== 'active' ? (
                        <IonIcon icon={refreshCircleOutline} onClick={e => onRestore(e, item.id)} />
                    ) : (
                        <IonIcon icon={trashOutline} onClick={e => onDelete(e, item.id)} />
                    )}
                </EditSection>
            )}

            {parentprops.sortable && (
                <EditSection className={'right'}>
                    {' '}
                    <DragHandler />
                </EditSection>
            )}
        </ListItem>
    );
});

const List = SortableContainer(props => {
    const { id } = useParams<ParamTypes>();

    return (
        <ListHolder>
            {props.headerValues && props.headerValues.length > 0 && (
                <ListHeader>
                    {props.headerValues?.map(item => (
                        <ListHeaderItem key={item.name}>{item.label}</ListHeaderItem>
                    ))}
                </ListHeader>
            )}
            {props.items.map((item, index) => {
                if (props.search && item.name.toLowerCase().indexOf(props.search) !== -1)
                    return (
                        <SetListItem
                            clickIndex={index}
                            key={item.id}
                            item={item}
                            index={index}
                            parentprops={props}
                            id={id}
                        />
                    );
                else if (!props.search)
                    return (
                        <SetListItem
                            clickIndex={index}
                            key={item.id}
                            item={item}
                            index={index}
                            parentprops={props}
                            id={id}
                        />
                    );
                else return <></>;
            })}
        </ListHolder>
    );
});

const ReturnList = props => {
    const items = [...props.data];
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newList = arrayMove(items, oldIndex, newIndex);
        const saveList = newList.map(({ id, name }, index) => {
            return {
                id,
                name,
                index,
            };
        });

        props.save({
            variables: { [props.dataName + 'Data']: saveList },
        });
    };
    if (props.sortable) {
        items.sort((a, b) => {
            return a.index - b.index;
        });
    }

    const newProps = { ...props, items };
    if (items.length === 0) return <EmptyList>No items</EmptyList>;
    else return <List {...newProps} useDragHandle onSortEnd={onSortEnd} />;
};
export default ReturnList;
