import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Input } from 'reactstrap';
import { Block, List, Creator, Loader } from 'components';
import Filters from 'containers/Filters';
import { notify } from 'react-notify-toast';

interface Props {
    title: string;
    filters: boolean;
    listHeaders;
    listData;
    containerId: string;
    mutation?;
    save?;
    dataName: string;
    newItem?;
    query;
    onClick?;
    clickUrl?;
    sortable?: boolean;
    global?: boolean;
    deleteable?: boolean;
    listState?: boolean;
}

// For future me, changing filter will not re-render list.

export default function ListContainer({
    title,
    filters,
    listHeaders,
    listData,
    dataName,
    newItem,
    save,
    query,
    sortable,
    onClick,
    clickUrl,
    containerId,
    global,
    deleteable,
}: Props) {
    const [search, setSearch] = useState('');
    const history = useHistory();
    const removeable = deleteable !== undefined ? false : true;
    // const { containerId } = useParams<{ containerId: string }>();

    const create = (name, containerId, global) => {
        save({
            variables: { [dataName + 'Data']: [newItem(name, containerId, global)] },
            onComplete: () => {
                notify.show('Item saved', 'success', 1000);
            },
        });
    };

    const changeState = (clickedId, state) => {
        save({
            variables: { [dataName + 'Data']: [{ id: clickedId, state: state }] },
            onComplete: () => {
                notify.show('Item saved', 'success', 1000);
            },
        });
    };

    if (query.loading || !query.data) return <Loader />;
    if (query.error) return <p>Error :(</p>;
    const currentList =
        listData.length > 0
            ? listData.slice().sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) {
                      return -1;
                  }
                  if (a.name.toLowerCase() > b.name.toLowerCase()) {
                      return 1;
                  }
                  return 0;
              })
            : [];

    return (
        <>
            {filters && removeable && <Filters state={query.data.stateFilter} />}
            <Block
                title={title || ''}
                headerArea={
                    <Input type='text' name='search' onChange={({ target }) => setSearch(target.value)} />
                }
            >
                {query.data.stateFilter === 'active' && removeable && newItem && currentList.length > 10 && (
                    <Creator
                        type={dataName}
                        onSubmit={create}
                        containerId={containerId}
                        global={global}
                        itemList={currentList}
                    />
                )}
                <List
                    dataName={dataName}
                    search={search}
                    state={query.data.stateFilter || 'active'}
                    onClick={clickedId => {
                        if (onClick) onClick(clickedId);
                        else {
                            history.push(`${clickUrl}${clickedId}`);
                        }
                    }}
                    onDelete={clickedId => changeState(clickedId, 'deleted')}
                    onRestore={clickedId => changeState(clickedId, 'active')}
                    headerValues={listHeaders || [{ name: 'name', label: 'Name' }]}
                    save={save}
                    data={currentList}
                    deleteable={removeable}
                    sortable={sortable}
                    global={global}
                />
                <hr />
                {query.data.stateFilter === 'active' && newItem && (
                    <Creator
                        type={dataName}
                        onSubmit={create}
                        containerId={containerId}
                        global={global}
                        itemList={currentList}
                    />
                )}
            </Block>
        </>
    );
}
