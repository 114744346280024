import { gql } from '@apollo/client';
import { NetworkProfile, Sizes } from 'interfaces/networkProfile';
import shortid from 'shortid';

export const GET_NETWORK_PROFILE = gql`
    query getNetworkProfile {
        getNetworkProfile {
            profile
        }
    }
`;

export const UPDATE_NETWORK_PROFILE = gql`
    mutation updateNetworkProfile($profileData: JSON) {
        editNetworkProfile(profileData: $profileData) {
            profile
        }
    }
`;

export const newSize = (): Sizes => {
    return {
        id: shortid.generate(),
        values: [null, null],
    };
};

export const newItem = (name): NetworkProfile => {
    return {
        id: shortid.generate(),
        name: name,
        sizes: [
            {
                id: shortid.generate(),
                values: [null, null],
            },
        ],
    };
};
