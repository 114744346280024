import React from 'react';
import styled from 'styled-components';

const HighlightHolder = styled.span`
    background: #fddbe0;
    border-radius: 3px;
    border: 1px solid #efa8b2;
    font-family: monospace;
    margin: 2px;
    padding: 3px 2px;
    color: #ca4d60;
`;

const Highlight = props => <HighlightHolder>{props.children}</HighlightHolder>;

export { Highlight };
