import styled from 'styled-components';
import { colors, ButtonStyle } from 'style';

export const AddHolder = styled.button`
    ${ButtonStyle}
    margin-top: 0;
    cursor: pointer;

    ion-icon {
        font-size: 24px !important;
        margin-right: 10px;
    }
`;

export const CreatorHolder = styled.div`
    input {
        float: left;
        width: calc(100% - 120px);
    }

    input[type='submit'] {
        ${ButtonStyle}
        text-align: center;
        background: ${colors.primary};
        float: right;
    }

    p {
        cursor: pointer;
        margin-top: 10px;
        font-size: 12px;
        float: left;
    }
`;
