import React from 'react';
import { Container } from 'reactstrap';
import { UPDATE_VARIABLE, GET_GLOBAL_VARIABLES, newVariable } from 'requests/variables';
import { Variable } from 'interfaces/variables';
import { useList } from 'utils/useList';
import { useParams } from 'react-router';
import Edit from 'views/Containers/Variables/Edit';
import { SlideOverlay } from 'components';

export interface Request {
    getVariables: Variable[];
}
export interface MutateVariables {
    variableData: Variable;
}

function VariablesView() {
    const { id } = useParams<{ id: string }>();
    const list = useList<Request, MutateVariables, Variable>({
        parent: 'network',
        type: 'variable',
        request: GET_GLOBAL_VARIABLES,
        mutation: UPDATE_VARIABLE,
        global: true,
        createItem: newVariable,
        headers: [
            { name: 'name', label: 'Name' },
            { name: 'type', label: 'Type' },
            { name: 'default', label: 'Default' },
        ],
    });

    return (
        <Container>
            <h1>Variables</h1>
            {list}
            {id && (
                <SlideOverlay>
                    <Edit id={id} />
                </SlideOverlay>
            )}
        </Container>
    );
}

export default VariablesView;
