import React from 'react';
import { Holder, Title, Content } from './style';

interface Props {
    active?: '' | 'active';
    type?: '' | 'active' | 'clickable' | 'warning';
    style?: any;
    title?: string;
    headerArea?: React.ReactNode;
    children: React.ReactNode;
    onClick?: () => void;
}
const Card = (props: Props) => {
    return (
        <Holder {...props} className={props.type}>
            {(props.title || props.headerArea) && (
                <Title>
                    {props.title && props.title}
                    <div className='headerButton'>{props.headerArea && props.headerArea}</div>
                </Title>
            )}

            <Content>{props.children}</Content>
        </Holder>
    );
};
export default Card;
