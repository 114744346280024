import React from 'react';
import { Label, Row, Col } from 'reactstrap';
import { DeletableRow } from 'style';
import { Button, AddButton, RemoveButton, Form, Input } from 'components';
import { newSize } from 'requests/networkProfile';
import { clean } from 'utils';

const Edit = ({ item, state, index, mutation, save }) => {
    const sizeTemplate = `profile.sizeTemplates[${index}]`;
    return (
        <Form state={state}>
            <Row>
                <Col>
                    <Label>Name</Label>
                    <Input name={`${sizeTemplate}.name`} />
                </Col>
            </Row>

            <hr />

            {item.sizes &&
                item.sizes.map((size, i) => {
                    const ref = i === item.sizes.length - 1 && size.values[0] === null ? true : false;
                    return (
                        <DeletableRow key={size.id}>
                            <Col md={5}>
                                <Input
                                    autoFocus={ref}
                                    type='number'
                                    name={`${sizeTemplate}.sizes[${i}].values[0]`}
                                />
                            </Col>
                            <Col md={5}>
                                <Input type='number' name={`${sizeTemplate}.sizes[${i}].values[1]`} />
                            </Col>
                            <RemoveButton onClick={() => state.splice(`${sizeTemplate}.sizes`, i)} />
                        </DeletableRow>
                    );
                })}

            <Button
                loader={mutation.loading}
                onClick={() => {
                    save({ variables: { profileData: clean(state.current.profile) } });
                }}
            >
                Save
            </Button>

            <AddButton
                size='small'
                onClick={() => state.push(`${sizeTemplate}.sizes`, newSize())}
            ></AddButton>
        </Form>
    );
};

export default Edit;
