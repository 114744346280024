import styled from 'styled-components';
import { colors } from 'style';

export const ListHolder = styled.div`
    width: 100%;

    a {
        display: block;
        width: 100%;
    }
    .active {
        background: ${colors.primary};
        color: #fff !important;
    }
    &.active div {
        color: #fff !important;
    }
`;

export const DragHandle = styled.span`
    display: block;
    font-size: 20px;
`;

export const ListItem = styled.div`
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    width: 100%;
    padding: 20px 30px;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #dfe0e2;
    box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;

    &:hover {
        background: #f4f5f7;
    }

    &.global {
        box-shadow: -4px 0px 0px rgb(78 158 245);
        opacity: 0.5;
    }
`;

export const ItemSection = styled.div`
    width: 25%;
    color: #abaeaf;

    text-align: left;
    &:first-of-type {
        color: #414242;
        width: 50%;
    }
    &.sortable:first-of-type {
        padding-left: 50px;
    }
`;

export const EditSection = styled.div`
    right: 25px;
    top: 15px;
    background: white;
    position: absolute;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0px 1px 3px rgba(82, 85, 86, 0.25);
    transition: all 0.2s;

    &:hover {
        background: ${colors.primary};
        ion-icon {
            color: ${colors.lightGrey};
        }
    }

    ion-icon {
        transition: all 0.2s;
        cursor: pointer;
        font-size: 24px;
        float: right;
        color: ${colors.mediumGrey};
    }

    &.right {
        width: 35px;
        left: 25px;
    }
`;

export const ListHeader = styled.div`
    width: 100%;
    padding: 10px 30px;
    margin: 10px 0px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.01);
    border: 1px solid #dfe0e2;
    display: flex;
    justify-content: flex-start;
`;

export const ListHeaderItem = styled.div`
    font-weight: bold;
    width: 25%;
    text-align: left;

    &:first-of-type {
        width: 50%;
    }
`;

export const EmptyList = styled.div`
    width: 100%;
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: ${colors.lightGrey};
    text-shadow: 0px -1px 0px ${colors.mediumGrey};
`;
